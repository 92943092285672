import { ToastProps } from '../../../_types/ComponentProps';
import { toast } from '@vereign/ui';

export default function SuccessToast({ message }: ToastProps) {
  return toast.success(message, {
    className: '',
    description: '',
    duration: 5000,
    // icon: <SuccessIconExample />,
  });
}
