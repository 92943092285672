import { Dispatch, DragEvent, RefObject, SetStateAction } from 'react';
import {
  AttachmentState,
  IntSEALObject,
  ReplyMailData,
} from '../_types/DataTypes';
import ErrorToast from '../_comps/Toast/ErrorToast/ErrorToast';
import Queue from 'queue-promise';
import { megabyte } from '../_config/schema';
import {
  AuthApiException,
  AuthClient,
  DeleteResponse,
  ErrorDto,
} from '../_clients/auth';

export function textAreaChange(
  ev: React.ChangeEvent<HTMLTextAreaElement>,
  outsideStateSetter: Dispatch<SetStateAction<ReplyMailData>>,
) {
  if (ev.target.value.length <= +ev.target.maxLength) {
    outsideStateSetter((prev: any) => ({
      ...prev,
      body: ev.target.value,
    }));
  }
  if (ev.target.value.length < +ev.target.maxLength) {
    ev.target.style.outlineColor = '';
  } else {
    ev.target.style.outlineColor = 'red';
  }
}

export function resizeTextAreaToContent(
  textAreaRef: RefObject<HTMLTextAreaElement>,
) {
  /* istanbul ignore next */
  if (!textAreaRef.current) return;
  textAreaRef.current.style.height = 'auto';
  textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
}

export const validateSinglefile = ({
  file,
  maxSize,
}: {
  file: Blob;
  maxSize: number;
}) => {
  const goodResponse = {
    success: true,
    message: 'File passed validation.',
  };

  if (file.size > maxSize) {
    return {
      success: false,
      message: `File size too big. Maximum allowed: ${maxSize / megabyte} MB.`,
    };
  }

  return goodResponse;
};

export const validateFilesAndCompare = (
  prevFileArray: AttachmentState[],
  currentFileArray: File[],
  maxFileSize: number,
) => {
  if (!currentFileArray.length) {
    return {
      reject: false,
      resArray: [],
    };
  }

  let reject = false;
  for (let i = 0; i < prevFileArray?.length; i++) {
    for (let j = 0; j < currentFileArray?.length; j++) {
      if (
        currentFileArray[j].name == prevFileArray[i].name
        // && currentFileArray[j].type == prevFileArray[i].type
      ) {
        reject = true;
      }
    }
  }

  let resArray = [];
  for (let i = 0; i < currentFileArray.length; i++) {
    let validationResult = validateSinglefile({
      file: currentFileArray[i],
      maxSize: maxFileSize,
    });
    // INFO: already tested by validateSinglefile tests
    /* istanbul ignore next */
    if (!validationResult.success) {
      reject = true;
      resArray.push(validationResult);
      ErrorToast({ message: validationResult.message });
    }
  }

  // TODO: remove validations for file size -> backend will handle

  return {
    reject,
    resArray: resArray,
  };
};

export const dropHandler = (
  ev: DragEvent<HTMLDivElement>,
  prev: AttachmentState[],
  maxFileSize: number,
) => {
  let fileArray: File[] | null = [];

  if (ev.dataTransfer.items) {
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
      if (ev.dataTransfer.items[i].kind !== 'file') {
        // console.log('Not a file or not supported.');
        continue;
      }
      let file = ev.dataTransfer.items[i].getAsFile();
      if (file) fileArray.push(file);
    }
  } else {
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      let file = ev.dataTransfer.files[i];
      if (file) fileArray.push(file);
    }
  }

  let { reject, resArray } = validateFilesAndCompare(
    prev,
    fileArray,
    maxFileSize,
  );

  if (reject) {
    fileArray = [];
  }

  return {
    files: fileArray,
    reject,
    resArray,
  };
};

export async function handleFileUploadRequest({
  file,
  qr,
  authClient,
}: {
  file: File;
  qr: IntSEALObject;
  authClient: AuthClient;
}) {
  const filename = file.name;
  const messsageId = qr.messageId!;
  const sealHash = qr.sealHash!;
  const arrayBuff = await file.arrayBuffer();
  const data = new Uint8Array(arrayBuff);

  try {
    const res = await authClient.upload(messsageId, sealHash, filename, data);
    return res;
  } catch (err) {
    if (AuthApiException.isAuthApiException(err)) {
      throw {
        fileName: file.name,
        errCode: err.errorDto.errorCode,
        message: err.message,
      };
    }
    const error = {
      fileName: file.name,
      errCode: 'unknown error code',
      message: file.name,
    } as ErrorDto;
    throw new AuthApiException(error);
  }
}

export const removeAllFiles = async ({
  files,
  onRequest,
  onEachResolve,
  onEnd,
}: {
  files: AttachmentState[];
  onRequest: (file: AttachmentState) => Promise<DeleteResponse | undefined>;
  onEachResolve: (data: any) => void;
  onEnd: () => void;
}) => {
  const Q = new Queue({
    concurrent: 1,
  });

  files.forEach(async (file) => {
    Q.enqueue(async () => onRequest(file));
  });

  Q.on('resolve', (data) => onEachResolve(data));

  Q.on('end', onEnd);

  return;
};
