'use client';

import AuthPage from './_pages/AuthPage/AuthPage';
import EmailPage from './_pages/EmailPage/EmailPage';
import SettingsPage from './_pages/SettingsPage/SettingsPage';
import useUserStore from './_stores/UserStore';

export default function Home() {
  const isAuthenticated = useUserStore((state) => state.authenticatedEmail);
  const isSettingsOpen = useUserStore((state) => state.isSettingsOpen);

  if (!isAuthenticated) {
    return <AuthPage />;
  }

  if (isSettingsOpen) {
    return <SettingsPage />;
  }

  return <EmailPage />;
}
