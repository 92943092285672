import { twMerge } from 'tailwind-merge';

const Circle = ({ filled = false }) => {
  return (
    <div
      className={twMerge(
        'h-2.5 w-2.5 rounded-full',
        filled ? 'bg-primary' : 'border border-secondary-foreground bg-white',
      )}
    ></div>
  );
};

export default Circle;
