export class AuthClient {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private async processResponse(response: Response) {
    const status = response.status;

    if (status === 200) {
      return response.text().then((responseText) => {
        let result: any = null;
        result = responseText === '' ? null : JSON.parse(responseText);
        return result;
      });
    } else if (
      status === 400 ||
      status === 401 ||
      status === 403 ||
      status === 500
    ) {
      const error = (await response.json()) as ErrorDto;
      return throwException(error);
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return new Error('An unexpected server error occurred.');
      });
    }
    return Promise.resolve(null);
  }

  /**
   * Logout clears and destroys the current session.
   * @return OK
   */
  logout(): Promise<void> {
    return fetch(this.baseUrl + '/logout', {
      method: 'GET',
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response);
    });
  }

  /**
   * Readiness endpoint returns the status of the service
   * @return OK
   */
  readiness(): Promise<HealthResponse> {
    return fetch(this.baseUrl + '/readiness', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }).then((_response: Response) => {
      return this.processResponse(_response) as HealthResponse;
    });
  }

  /**
   * VerifySMSCode endpoint verifies given SMS code
   * @param verifySMSCodeRequest VerifySMSCodeRequest
   * @return OK
   */
  verifySMSCode(
    verifySMSCodeRequest: VerifySMSCodeRequest,
  ): Promise<VerifySMSCodeResponse> {
    return fetch(this.baseUrl + '/v1/auth/sms', {
      body: JSON.stringify(verifySMSCodeRequest),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as VerifySMSCodeResponse;
    });
  }

  /**
   * AuthUser endpoint
   * @param authUserRequest AuthUserRequest
   * @return OK
   */
  authUser(authUserRequest: AuthUserRequest): Promise<AuthUserResponse> {
    return fetch(this.baseUrl + '/v1/auth/user', {
      body: JSON.stringify(authUserRequest),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as AuthUserResponse;
    });
  }

  /**
   * User settings endpoint
   * @param setPreferedLangRequest ChangePreferedLangRequest
   * @return OK
   */
  changePreferedLang(
    setPreferedLangRequest: ChangePreferedLangRequest,
  ): Promise<ChangePreferedLangResponse> {
    return fetch(this.baseUrl + '/v1/user/settings', {
      body: JSON.stringify(setPreferedLangRequest),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as ChangePreferedLangResponse;
    });
  }

  /**
   * Config returns configuration information.
   * @return OK
   */
  config(): Promise<ConfigResponse> {
    return fetch(this.baseUrl + '/v1/config', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as ConfigResponse;
    });
  }

  /**
   * VerifyEmail sends a verification email for updating mobile number to the given email address.
   * @param verifyEmailRequest VerifyEmailRequest
   * @return OK
   */
  verifyEmail(
    verifyEmailRequest: VerifyEmailRequest,
  ): Promise<VerifyEmailResponse> {
    return fetch(this.baseUrl + '/v1/email/verify', {
      body: JSON.stringify(verifyEmailRequest),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((_response: Response) => {
      return this.processResponse(_response) as VerifyEmailResponse;
    });
  }

  /**
   * KeyDecrypt authenticates the user and decrypts given seal key
   * @param keyDecryptRequest KeyDecryptRequest
   * @return OK
   */
  keyDecrypt(
    keyDecryptRequest: KeyDecryptRequest,
  ): Promise<KeyDecryptResponse> {
    return fetch(this.baseUrl + '/v1/key/decrypt', {
      body: JSON.stringify(keyDecryptRequest),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as KeyDecryptResponse;
    });
  }

  /**
   * MessageFiles returns attachment files from current session for a reply message.
   * @param x_Message_Id Message ID
   * @param x_Message_Hash Message Hash
   * @return OK
   */
  getMessageFiles(
    x_Message_Id: string,
    x_Message_Hash: string,
  ): Promise<FilesResponse> {
    return fetch(this.baseUrl + '/v1/message/files', {
      method: 'GET',
      headers: {
        'X-Message-Id': x_Message_Id,
        'X-Message-Hash': x_Message_Hash,
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as FilesResponse;
    });
  }

  /**
   * DeleteAllFiles removes message attachment files from storage and session.
   * @param x_Message_Id Message ID
   * @param x_Message_Hash Message Hash
   * @return OK
   */
  deleteAllFiles(
    x_Message_Id: string,
    x_Message_Hash: string,
  ): Promise<DeleteAllFilesResponse> {
    return fetch(this.baseUrl + '/v1/message/files', {
      method: 'DELETE',
      headers: {
        'X-Message-Id': x_Message_Id,
        'X-Message-Hash': x_Message_Hash,
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as DeleteAllFilesResponse;
    });
  }

  /**
   * DeleteFile removes message attachment file from storage.
   * @param filename Name of the file
   * @param x_Message_Id Message ID
   * @param x_Message_Hash Message Hash
   * @return OK
   */
  deleteFile(
    filename: string,
    x_Message_Id: string,
    x_Message_Hash: string,
  ): Promise<DeleteResponse> {
    let url =
      this.baseUrl + '/v1/message/files/' + encodeURIComponent(filename);
    let options_: RequestInit = {
      method: 'DELETE',
      headers: {
        'X-Message-Id': x_Message_Id,
        'X-Message-Hash': x_Message_Hash,
        Accept: 'application/json',
      },
      credentials: 'include',
    };

    return fetch(url, options_).then((_response: Response) => {
      return this.processResponse(_response) as DeleteResponse;
    });
  }

  /**
   * VerifyMobile verifies the mobile number by sending verification code.
   * @param verifyMobileRequest VerifyMobileRequest
   * @return OK
   */
  verifyMobile(
    verifyMobileRequest: VerifyMobileRequest,
  ): Promise<VerifyMobileResponse> {
    return fetch(this.baseUrl + '/v1/mobile', {
      body: JSON.stringify(verifyMobileRequest),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as VerifyMobileResponse;
    });
  }

  /**
   * VerifyNewMobileSMSCode verifies sms code sent to new mobile number.
   * @param verifyNewMobileSMSCodeRequest VerifyNewMobileSMSCodeRequest
   * @return OK
   */
  verifyNewMobileSMSCode(
    verifyNewMobileSMSCodeRequest: VerifyNewMobileSMSCodeRequest,
  ): Promise<VerifyNewMobileSMSCodeResponse> {
    return fetch(this.baseUrl + '/v1/mobile/verify', {
      body: JSON.stringify(verifyNewMobileSMSCodeRequest),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as VerifyNewMobileSMSCodeResponse;
    });
  }

  /**
   * Reply handles the reply to a message
   * @param request ReplyRequest
   * @return OK
   */
  reply(request: ReplyRequest): Promise<ReplyResponse> {
    return fetch(this.baseUrl + '/v1/reply', {
      body: JSON.stringify(request),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response) as ReplyResponse;
    });
  }

  /**
   * Session returns current session associated with the cookie of the incoming request.
   * @return OK
   */
  session(): Promise<SessionResponse> {
    return fetch(this.baseUrl + '/v1/session', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    }).then((_response: Response) => {
      return this.processResponse(_response);
    });
  }

  /**
   * Upload authenticates the user and uploads a file to S3
   * @param x_Message_Id Message ID
   * @param x_Message_Hash Message Hash
   * @param filename Name of the file
   * @param file File to upload (raw binary data)
   * @return OK
   */
  upload(
    x_Message_Id: string,
    x_Message_Hash: string,
    filename: string,
    file: Uint8Array,
  ): Promise<UploadResponse> {
    let url_ = this.baseUrl + '/v1/upload/{filename}';
    if (filename === undefined || filename === null)
      throw new Error("The parameter 'filename' must be defined.");
    url_ = url_.replace('{filename}', encodeURIComponent('' + filename));

    let options_: RequestInit = {
      body: file,
      method: 'POST',
      headers: {
        'X-Message-Id':
          x_Message_Id !== undefined && x_Message_Id !== null
            ? '' + x_Message_Id
            : '',
        'X-Message-Hash':
          x_Message_Hash !== undefined && x_Message_Hash !== null
            ? '' + x_Message_Hash
            : '',
        'Content-Type': 'application/octet-stream',
        Accept: 'application/json',
      },
      credentials: 'include',
    };

    return fetch(url_, options_).then((_response: Response) => {
      return this.processResponse(_response);
    });
  }

  /**
   * UpdateUserSettings
   * @return OK
   */
  settings(): Promise<User> {
    return fetch(this.baseUrl + '/v1/user/settings', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    }).then((_response: Response) => {
      return this.processResponse(_response) as User;
    });
  }

  /**
   * GetUser returns stored user details and settings.
   * @return OK
   */
  getUser(user: string): Promise<User> {
    let url = this.baseUrl + '/v1/user/' + encodeURIComponent(user);
    let options: RequestInit = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    };

    return fetch(url, options).then((_response: Response) => {
      return this.processResponse(_response) as User;
    });
  }
}

export interface User {
  email?: string | null;
  mobile?: string | null;
  settings?: UserSettings | null;
}

export interface UserSettings {
  language?: string | null;
}

export interface ErrorDto {
  /** The underlying error that triggered this one, if any. */
  err?: any | null;
  /** Optional error code string that can be used as subtype. */
  errorCode?: string | null;
  /** ID is a unique error identifier. */
  id?: string | null;
  /** Kind of error returned to the caller. */
  kind?: number;
  /** Message is a description of the error. */
  message?: string | null;
}

export interface AuthUserRequest {
  email?: string | null;
  messageHash?: string | null;
  mobile?: string | null;
  language?: string | null;
}

export interface AuthUserResponse {
  email?: string | null;
  mobile?: string | null;
}

export interface ChangePreferedLangRequest {
  language?: string | null;
}

export interface ChangePreferedLangResponse {
  email?: string;
  language?: string;
  mobile?: string;
}

export interface ConfigResponse {
  forbiddenAttachmentTypes?: string[] | null;
  maxTotalUploadSize?: number | null;
  maxUploadSize?: number | null;
  supportedCountryCodes?: { [key: string]: string } | null;
}

export interface DeleteAllFilesResponse {
  files?: File[] | null;
  remainingUploadSize?: number | null;
}

export interface DeleteResponse {
  filename?: string | null;
  remainingUploadSize?: number | null;
  size?: number | null;
}

export interface File {
  name?: string | null;
  size?: number | null;
}

export interface FilesResponse {
  files?: File[] | null;
  /** Remaining specifies the remaining attachments upload size. */
  remainingUploadSize?: number | null;
}

export interface HealthResponse {
  service?: string | null;
  status?: string | null;
  version?: string | null;
}

export interface KeyDecryptRequest {
  messageHash?: string | null;
  sealKey?: string | null;
}

export interface KeyDecryptResponse {
  decryptedKey?: string;
}

export interface ReplyRequest {
  bcc?: string | null;
  body?: string | null;
  messageHash?: string | null;
  messageId?: string | null;
  recipient?: string | null;
  subject?: string | null;
}

export interface ReplyResponse {
  messageHash?: string | null;
  messageId?: string | null;
}

export interface SessionResponse {
  email?: string;
  expires: number;
  sid: string;
}

export interface UploadResponse {
  filename?: string | null;
  remainingUploadSize?: number | null;
  size?: number | null;
}

export interface VerifyEmailRequest {
  email?: string | null;
  oldMobile?: string | null;
}

export interface VerifyEmailResponse {
  email?: string | null;
}

export interface VerifyMobileRequest {
  code?: string | null;
  email?: string | null;
  newMobile?: string | null;
}

export interface VerifyMobileResponse {
  email?: string | null;
  newMobile?: string | null;
}

export interface VerifyNewMobileSMSCodeRequest {
  smsCode?: string | null;
}

export interface VerifyNewMobileSMSCodeResponse {
  newMobile?: string | null;
}

export interface VerifySMSCodeRequest {
  rememberMe?: boolean | null;
  smsCode?: string | null;
}

export interface VerifySMSCodeResponse {
  authenticated?: boolean | null;
}

export class AuthApiException extends Error {
  override message: string;
  errorDto: ErrorDto;

  constructor(error: ErrorDto, message?: string) {
    super();

    this.errorDto = error;
    this.message = message || error.message || 'unknown error';
  }

  protected isAuthApiException = true;

  static isAuthApiException(obj: any): obj is AuthApiException {
    return obj.isAuthApiException === true;
  }
}

function throwException(error: ErrorDto, message?: string): any {
  throw new AuthApiException(error, message);
}
