import { Card, CardHeader, Headline, Text } from '@vereign/ui';
import { useTranslation } from 'next-export-i18n';
import Link from '@/app/_comps/Link/Link';
import { Config } from '@/app/_config/schema';
import { withConfig } from '@/app/_hocs/withConfig';

function NotFound({ config }: { config: Config }) {
  const { t } = useTranslation();

  return (
    <Card className="flex max-w-screen-xl flex-row">
      <CardHeader className="flex flex-col items-center gap-8 lg:flex-row lg:p-32">
        <img
          src="/graphics/404.svg"
          alt="laptop with 404 sign"
          className="hidden w-1/3 lg:block"
        />
        <div className="flex flex-1 flex-col gap-8">
          <div className="flex flex-row gap-4">
            <img src="/graphics/warning-red.svg" alt="warning icon" />
            <Headline as="h2" className="text-3xl font-bold lg:text-4xl">
              {t('pages.notFoundPage.title')}
            </Headline>
          </div>
          <Text as="p">{t('pages.notFoundPage.description')}</Text>

          <Text as="p">
            {t('common.help')}
            {` `}
            <Link className="font-bold" href={config.general.support} external>
              {t('common.links.hinSupport')}
            </Link>
          </Text>

          <div className="flex flex-row justify-center lg:hidden">
            <img
              src="/graphics/404.svg"
              alt="laptop with 404 sign"
              className="h-64 w-64"
            />
          </div>
        </div>
      </CardHeader>
    </Card>
  );
}

export default withConfig(NotFound);
