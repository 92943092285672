import { md5 } from './md5';

// export const b64Regex =
//   /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/g;

export const emailRegex = /^[^@\s]+@[^@\s]+$/g;

export const truncateString = (str: string, length: number): string => {
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
};

export const generateColorFromString = (
  str: string,
  saturation = 99,
  lightness = 75,
) => {
  let hash = 0;
  let salt = 123081353;

  if (saturation < 0) saturation = 0;
  if (saturation > 99) saturation = 99;
  if (lightness < 0) lightness = 0;
  if (lightness > 99) lightness = 99;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & (hash + salt);
  }
  if (str === '') {
    return `hsl(25, 0%, 0%)`;
  }
  return `hsl(${Math.abs(hash % 360)}, ${saturation}%, ${lightness}%)`;
};

export const generateGradient = (str: string | undefined) => {
  let senderEmail = str || 'undefined.email@example.com';
  const seedStrings = senderEmail.split('@');

  let colorOne = 'hsl(0, 100%, 100%)';
  let colorTwo = generateColorFromString(seedStrings[0]);

  let randomAngle = Math.floor((1 / 'undefined'.length) * 360);

  if (str) {
    randomAngle = Math.floor((1 / str.length) * 360);
  }

  return `linear-gradient(${randomAngle}deg, ${colorOne}, ${colorTwo})`;
};

export const emailHash = (email: string | undefined) => {
  if (email === undefined) {
    throw new Error('No email provided.');
  }

  let hash = md5(email.toLowerCase().trim());
  return hash;
};

export function normalizeUserEmail(userEmail: string) {
  const [user, domain] = userEmail.toLowerCase().split('@');

  const normalizedUser = user.split('+')[0];

  return `${normalizedUser}@${domain}`;
}

export const encodeBase64Url = (base64: string): string => {
  return base64.replace(/\+/g, '-').replace(/\//g, '_');
};

export const decodeBase64URL = (input: string): string => {
  return input.replace(/_/g, '/').replace(/-/g, '+');
};

export const toArrayBuffer = (buffer: Buffer) => {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(arrayBuffer);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return arrayBuffer;
};

export const ensureUint8Array = (
  data: string | ArrayBuffer | Uint8Array,
): Uint8Array => {
  return data instanceof Uint8Array
    ? data
    : typeof data === 'string'
      ? new Uint8Array(Buffer.from(data, 'base64'))
      : data instanceof ArrayBuffer
        ? new Uint8Array(data)
        : data;
};

// INFO: keep for reference in case it is needed
// export const byteToBase64 = (string: string) => {
//   const codeUnits = new Uint16Array(string.length);
//   for (let i = 0; i < codeUnits.length; i++) {
//     codeUnits[i] = string.charCodeAt(i);
//   }
//   return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
// };

export const base64ToByte = (encoded: string) => {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return new Uint8Array(bytes.buffer);
};

export function base64ToHex(str: string) {
  const raw = atob(str);
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    /* istanbul ignore next */
    result += hex.length === 2 ? hex : '0' + hex;
  }
  return result.toLowerCase();
}

export const convertByteSizeToReadable = (bytes: number) => {
  if (bytes / Math.pow(2, 30) > 0.1) {
    return (bytes / Math.pow(2, 30)).toFixed(1) + ' GB';
  }
  if (bytes / Math.pow(2, 20) > 0.1) {
    return (bytes / Math.pow(2, 20)).toFixed(1) + ' MB';
  }
  if (bytes / Math.pow(2, 10) > 0.1) {
    return (bytes / Math.pow(2, 10)).toFixed(1) + ' KB';
  }

  return bytes.toFixed(0) + ' B';
};

export const b64toBlob = (b64Data: string, contentType = '') => {
  if (!b64Data) {
    throw new Error('No base 64 data provided.');
  }
  let buffer = Buffer.from(b64Data, 'base64');
  const blob = new Blob([buffer], { type: contentType });
  return blob;
};

export function isValueInStringEnum<E extends string>(
  strEnum: Record<string, E>,
) {
  const enumValues = Object.values(strEnum) as string[];

  return (value: string): value is E => enumValues.includes(value);
}

export const generateInitials = (
  email: string | undefined,
  name: string | undefined,
) => {
  if (name) {
    let nameArr = name.split(' ');

    if (nameArr.length === 1) {
      return nameArr[0].substring(0, 1).toUpperCase();
    }

    return (
      nameArr[0].substring(0, 1).toUpperCase() +
      nameArr[1].substring(0, 1).toUpperCase()
    );
  }

  if (!name && email) {
    return email.substring(0, 1).toUpperCase();
  }

  return ' ';
};

export function arrayBufferToString(buf: ArrayBuffer) {
  return new TextDecoder().decode(buf);
}

export function unicodeToHex(
  str: string | Uint8Array | ArrayBuffer | undefined,
) {
  if (!str) {
    throw new Error('No string provided.');
  }
  return Buffer.from(str as string).toString('hex');
}

export function checkIfHtmlHasContent(htmlString: string): boolean {
  // Parse the HTML string into a DOM Document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Get the body element
  const body = doc.body;

  if (!body) {
    return false; // If no body exists, there's no content
  }

  // Recursive function to check for meaningful content
  function hasActualContent(node: Node): boolean {
    // Check for text nodes with non-whitespace content
    if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim() !== '') {
      return true;
    }

    // Check for element nodes and recurse into children
    if (node.nodeType === Node.ELEMENT_NODE) {
      for (const child of Array.from(node.childNodes)) {
        if (hasActualContent(child)) {
          return true;
        }
      }
    }

    return false;
  }

  // Check if the body contains any actual content
  return hasActualContent(body);
}
