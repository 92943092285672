import { Attachment } from '@/app/_types/DataTypes';
import { sumOfArray } from '@/app/_utils/functionUtils';
import { convertByteSizeToReadable } from '@/app/_utils/stringUtils';
import useAttachments, { LoadingHashes } from '@/app/_hooks/useAttachments';
import SingleAttachment from './SingleAttachment/SingleAttachment';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Headline,
  Text,
  DownloadIcon,
  AttachmentIcon,
} from '@vereign/ui';
import { useTranslation } from 'next-export-i18n';
import { Config } from '@/app/_config/schema';
import useQRStore from '@/app/_stores/QRStore';
import { IpfsClient } from '@/app/_clients/ipfs';
import Spinner from '@/app/_comps/Spinner/Spinner';
import { twMerge } from 'tailwind-merge';

const Attachments = ({ config }: { config: Config }) => {
  const { t } = useTranslation();
  const qrCodeData = useQRStore((state) => state.QR);
  const ipfsClient = new IpfsClient({
    ipfsAddr: config.ipfs.baseUrl,
    timeoutMs: 10000,
  });

  let attachments: Attachment[] | undefined;
  let sumSize: string = '';
  if (qrCodeData) {
    attachments = qrCodeData.attachments;

    if (attachments) {
      sumSize = convertByteSizeToReadable(
        sumOfArray({
          arr: attachments,
          initialValue: 0,
          arrayObjectKey: 'len',
        }),
      );
    }
  }

  const { loadingHashes, downloadSingleAttachment, downloadAllAttachments } =
    useAttachments(attachments, ipfsClient);

  const downloadAll = (ev: any) => {
    ev.preventDefault();

    let allAttachmentsArray = [];
    if (attachments) {
      for (let i = 0; i < attachments?.length; i++) {
        if (loadingHashes[attachments[i].cid]) {
          return;
        }
        allAttachmentsArray.push(attachments[i]);
      }
    }

    if (allAttachmentsArray.length < 1) {
      return;
    }

    if (allAttachmentsArray.length === 1) {
      downloadSingleAttachment(allAttachmentsArray[0]);
      return;
    }

    return downloadAllAttachments(allAttachmentsArray, ipfsClient, qrCodeData);
  };

  return (
    <Card
      data-testid="attachments-box"
      className="align-center flex flex-col justify-center"
    >
      <CardHeader>
        <Headline
          data-testid="attachments-headline"
          as="h4"
          className="px-10 text-center font-bold"
        >
          {t('common.attachments')}
        </Headline>
      </CardHeader>
      <CardContent>
        <div
          data-testid="attachments-meta-container"
          className="mb-8 flex flex-row justify-center gap-2.5"
        >
          <AttachmentIcon
            data-testid="attachments-icon"
            className="w-5 fill-primary"
          />
          {attachments && attachments.length > 0 ? (
            <Text
              data-testid="attachments-count-and-size"
              as="p"
              className="text-center text-[14px] font-bold"
            >
              {attachments.length == 1
                ? attachments.length + ' ' + t('common.attachment')
                : attachments.length + ' ' + t('common.attachmentsCount')}{' '}
              <span data-testid="attachments-size" className="font-normal">
                ({sumSize})
              </span>
            </Text>
          ) : (
            <Text
              as="p"
              data-testid="no-attachments-text"
              className="text-center text-xs font-bold"
            >
              {t('common.noAttachments')}
            </Text>
          )}
        </div>

        <div data-testid="attachments-list" className="flex flex-col gap-2">
          {attachments &&
            attachments.map((attachment, index) => {
              return (
                <SingleAttachment
                  attachmentCount={attachments.length}
                  key={attachment.cid}
                  attachment={attachment}
                  downloadSingleAttachment={downloadSingleAttachment}
                  loadingHashes={loadingHashes}
                  index={index}
                />
              );
            })}
        </div>
      </CardContent>

      {attachments && attachments.length > 0 && (
        <CardFooter>
          <Button
            disabled={Object.keys(loadingHashes).length > 0}
            data-testid="download-all-attachments-button"
            variant="default"
            size="full"
            className={twMerge(
              'py-3 font-bold lg:flex print:hidden',
              attachments.length > 1 ? 'hidden' : 'flex',
            )}
            onClick={(ev) => downloadAll(ev)}
          >
            {Object.keys(loadingHashes).length > 0 ? (
              <Spinner width={24} height={24} />
            ) : (
              <DownloadIcon className="mr-2 h-5 w-5 fill-white group-hover:fill-primary" />
            )}
            {attachments.length > 1
              ? t('common.buttons.downloadAll')
              : t('common.buttons.download')}
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default Attachments;
