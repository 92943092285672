import React from 'react';
import SkipLink from './SkipLink/SkipLink';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'next-export-i18n';
import useUserStore from '@/app/_stores/UserStore';

export interface SkipLinkData {
  href: string;
  textKey: string;
}

const authPageSkipLinks = [
  {
    href: '#phone-input',
    textKey: 'common.skipLinks.loginForm',
  },
];

const emailPageSkipLinks = [
  {
    href: '#email-meta',
    textKey: 'common.skipLinks.emailMeta',
  },
  {
    href: '#skip-to-email-content',
    textKey: 'common.skipLinks.emailBody',
  },
];

function SkipLinks() {
  const { t } = useTranslation();
  let skipLinks: SkipLinkData[] = [];

  const userEmail = useUserStore((state) => state.authenticatedEmail);

  const pathname = usePathname();

  const isSettingsOpen = useUserStore((state) => state.isSettingsOpen);

  if (pathname === '/') {
    if (!userEmail) {
      skipLinks = authPageSkipLinks;
    } else {
      skipLinks = isSettingsOpen ? [] : emailPageSkipLinks;
    }
  }

  return skipLinks.length > 0 ? (
    <ul className="hidden lg:block">
      {skipLinks.map(({ href, textKey }) => (
        <li key={href}>
          <SkipLink href={href}>{t(textKey)}</SkipLink>
        </li>
      ))}
    </ul>
  ) : null;
}

export default function SkipLinksWrapper() {
  return <SkipLinks />;
}
