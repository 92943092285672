import { Headline } from '@vereign/ui';
import Link from '../Link/Link';
import { useTranslation } from 'next-export-i18n';
import { withConfig } from '@/app/_hocs/withConfig';
import { Config } from '@/app/_config/schema';

interface DoYouNeedHelpBoxProps {
  config: Config | null;
  children?: React.ReactNode;
}

function DoYouNeedHelpBox({ config, children }: DoYouNeedHelpBoxProps) {
  const { t } = useTranslation();

  if (!config) return;

  return (
    <>
      <Headline as="h4" className="font-bold">
        {t('pages.authPage.help')}
      </Headline>
      <div className="flex flex-col gap-2">
        <span>{children}</span>
        <span>
          <Link
            href={config.general.support}
            className="text-sm font-medium"
            external
            data-testid="hin-support-link"
          >
            {t('common.links.hinSupport')}
          </Link>
        </span>
      </div>
    </>
  );
}

export default DoYouNeedHelpBox;
