import { AudienceOptions, IntSEALObject } from '@/app/_types/DataTypes';
import { create } from 'zustand';

export interface QRState {
  QR: IntSEALObject | undefined;
  setQR: (_qr: IntSEALObject | undefined) => void;
  audience: AudienceOptions | undefined;
  setAudience: (_audience: AudienceOptions | undefined) => void;
  senderImage: string | undefined;
  setSenderImage: (_a: string | undefined) => void;
}

const useQRStore = create<QRState>((set) => ({
  QR: undefined,
  setQR: (_qr) => set((state) => ({ QR: _qr })),
  audience: undefined,
  setAudience: (_audience) => set((state) => ({ audience: _audience })),
  senderImage: undefined,
  setSenderImage: (_a) => set((state) => ({ senderImage: _a })),
}));

export default useQRStore;
