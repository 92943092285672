import { z } from 'zod';

/* istanbul ignore file */
export const kilobyte = Math.pow(2, 10);
export const megabyte = Math.pow(2, 20);
export const gigabyte = Math.pow(2, 30);

const apiPathSchema = z.string();

const authSchema = z.object({
  baseUrl: z.string().url(),
});

const ipfsSchema = z.object({
  baseUrl: z.string().url(),
});

const avatarSchema = z.object({
  baseUrl: z.string(),
  getPath: apiPathSchema,
  dnsLookupPath: z.string().url(),
});

const supportedCountryCodesSchema = z.record(
  z.string().regex(/^\d+$/),
  z.string().length(2),
);

const generalSchema = z.object({
  support: z.string().url(),
  defaultToGravatarBool: z.boolean(),
  maxSingleFileSizeMb: z.number().positive(),
  maxTotalFilesSizeMb: z.number().positive(),
  maxReplyTextLen: z.number().positive(),
  supportedCountryCodes: supportedCountryCodesSchema,
});

export const configSchema = z.object({
  general: generalSchema,
  auth: authSchema,
  ipfs: ipfsSchema,
  avatar: avatarSchema,
});

export type Config = z.infer<typeof configSchema>;
