import { useEffect, useRef } from 'react';
import { Config } from '../_config/schema';
import { useConfigStore } from '../_stores/AppConfigStore';

export function useConfig(): {
  config: Config | null;
  fetchConfig: () => Promise<Config | null>;
} {
  const { config, fetchConfig } = useConfigStore();
  const isLoaded = useRef(false);

  useEffect(() => {
    (async () => {
      if (!config || !isLoaded.current) {
        isLoaded.current = true;
        await fetchConfig();
      }
    })();
  }, [config, fetchConfig]);

  return { config, fetchConfig };
}
