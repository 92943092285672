import { Checkbox, Label } from '@vereign/ui';
import { useTranslation } from 'next-export-i18n';
import React from 'react';

interface SendMeCopyCheckboxProps {
  id: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

function SendMeCopyCheckbox({
  id,
  checked,
  onCheckedChange,
}: SendMeCopyCheckboxProps) {
  const { t } = useTranslation();

  return (
    <>
      <Checkbox
        id={id}
        checked={checked}
        onCheckedChange={(checked) =>
          onCheckedChange(checked === 'indeterminate' ? false : checked)
        }
      />
      <Label className="cursor-pointer" htmlFor={id}>
        {t('pages.emailPage.sendCopy')}
      </Label>
    </>
  );
}

export default SendMeCopyCheckbox;
