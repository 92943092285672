import { Select, SelectContent, SelectTrigger, Text } from '@vereign/ui';
import {
  Country,
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import { twMerge } from 'tailwind-merge';
import CountrySelectItem from './CountrySelectItem';
interface CountrySelectProps {
  value: Country;
  onChange: (value: string) => void;
  iconComponent: React.FC<{ country: string }>;
}

const preferredCountries = ['CH', 'DE', 'FR', 'IT', 'AT', 'LI'] as Country[];

const allOtherCountries = getCountries().filter(
  (country) => !preferredCountries.includes(country),
);

export default function CountrySelect({
  value,
  onChange,
  iconComponent: Icon,
  ...rest
}: CountrySelectProps) {
  return (
    <Select {...rest} value={value} onValueChange={onChange}>
      <SelectTrigger
        className={twMerge(
          'flex h-11 w-auto gap-2 rounded-xl border-2 border-solid border-secondary-foreground px-3 py-2',
          'items-center rounded-br-none rounded-tr-none border-r-0 focus-visible:z-10',
          'text-left text-base font-bold text-primary-foreground placeholder:text-secondary-foreground',
          'focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0',
        )}
      >
        <Icon country={value} />
        <Text className="flex">{value}</Text>
        <Text>+{getCountryCallingCode(value)}</Text>
      </SelectTrigger>
      <SelectContent className="min-w-4">
        {preferredCountries.map((country) => (
          <CountrySelectItem key={country} country={country} Icon={Icon} />
        ))}
        <hr />
        {allOtherCountries.map((country) => (
          <CountrySelectItem key={country} country={country} Icon={Icon} />
        ))}
      </SelectContent>
    </Select>
  );
}
