export const decryptAESGCM = async ({
  data,
  key,
  iv,
}: {
  data: Uint8Array;
  key: Uint8Array;
  iv: Uint8Array;
}): Promise<ArrayBuffer> => {
  const importedKey = await crypto.subtle.importKey(
    'raw',
    key,
    {
      name: 'AES-GCM',
    },
    true,
    ['decrypt'],
  );

  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: iv },
    importedKey,
    data,
  );

  return decrypted;
};

export const sha256Digest = async (data: ArrayBuffer) => {
  const hash = await window.crypto.subtle.digest('SHA-256', data);
  return hash;
};
