export const generateDateForFileName = (date: Date) => {
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  let hour = new Intl.DateTimeFormat('en-GB', { hour: '2-digit' }).format(date);
  let minute = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(
    date,
  );

  return `${year}${month}${day}-${hour}${minute}`;
};
