import { create } from 'zustand';
import { configSchema, Config, megabyte } from '../_config/schema';
import { useRef } from 'react';

// Type for the store
type ConfigStore = {
  config: Config | null;
  fetchConfig: () => Promise<Config | null>;
};

// Transform function to handle type conversions
const transformConfig = (rawConfig: any): Config => {
  return {
    ...rawConfig,
    general: {
      ...rawConfig.general,
      defaultToGravatarBool: rawConfig.general.defaultToGravatarBool || false,
      maxSingleFileSizeMb:
        Number(rawConfig.general.maxSingleFileSizeMb) * megabyte ||
        2 * megabyte,
      maxTotalFilesSizeMb:
        Number(rawConfig.general.maxTotalFilesSizeMb) * megabyte ||
        10 * megabyte,
      maxReplyTextLen: Number(rawConfig.general.maxReplyTextLen) || 15000,
    },
  };
};

export const useConfigStore = create<ConfigStore>((set, get) => ({
  config: null,
  fetchConfig: async () => {
    if (get().config) {
      return get().config;
    }

    try {
      // Fetch the frontend configuration
      const response = await fetch('/config.json');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const rawConfig = await response.json();

      // Validate and transform the frontend configuration
      let validatedConfig = configSchema.parse(transformConfig(rawConfig));

      // Fetch the backend configuration
      const backEndRes = await fetch(
        `${validatedConfig.auth.baseUrl}/v1/config`,
      );
      if (!backEndRes.ok) {
        throw new Error(`HTTP error! status: ${backEndRes.status}`);
      }
      const rawBackEndConfig = await backEndRes.json();

      // Merge, transform, and validate the combined configuration
      validatedConfig = configSchema.parse(
        transformConfig({ ...validatedConfig, ...rawBackEndConfig }),
      );

      // Update the store with the new configuration
      set({ config: validatedConfig });

      return validatedConfig;
    } catch (error) {
      console.error('Failed to fetch config:', error);
      throw error; // Re-throw the error for further handling
    }
  },
}));
