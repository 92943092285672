import { Text } from '@vereign/ui';
import Link from '../Link/Link';
import { useTranslation } from 'next-export-i18n';
import { withConfig } from '@/app/_hocs/withConfig';
import { Config } from '@/app/_config/schema';

// Define the props interface to include config
interface FooterProps {
  config: Config;
}

const Footer = ({ config }: FooterProps) => {
  const { t } = useTranslation();

  if (!config) return null;

  return (
    <>
      <footer
        data-testid="footer"
        className="mt-auto flex flex-wrap content-center justify-center border-t-2 border-t-white bg-card px-10 py-4 shadow-2"
      >
        <section className="flex max-w-screen-sm flex-1 flex-col items-center justify-center gap-4 text-center lg:flex-row lg:text-start">
          <img
            data-testid="hin-label-img"
            src={t('footer.imageLink')}
            alt="HIN Label Logo"
            className="flex w-20 lg:w-24"
          />
          <Text data-testid="footer-text" as="p" className="">
            <span className="font-bold">{t('common.hinMail')}</span>
            {` `}
            {t('footer.paragraph')}
            {` `}
            <Link
              href={t('footer.labelLink')}
              external
              className="font-bold"
              data-testid="hin-label-link"
            >
              {t('common.links.learnMore')}
            </Link>
          </Text>
        </section>
      </footer>
    </>
  );
};

const ConfiguredFooter = withConfig(Footer);

export default ConfiguredFooter;
