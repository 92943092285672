import Spinner from '@/app/_comps/Spinner/Spinner';
import { LoadingHashes } from '@/app/_hooks/useAttachments';
import { Attachment } from '@/app/_types/DataTypes';
import { parseContentTypeHeader } from '@/app/_utils/SEALutils';
import {
  convertByteSizeToReadable,
  truncateString,
} from '@/app/_utils/stringUtils';
import {
  Button,
  DownloadIcon,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@vereign/ui';
import { useTranslation } from 'next-export-i18n';
import { twMerge } from 'tailwind-merge';

export const SingleAttachment = ({
  attachment,
  downloadSingleAttachment,
  loadingHashes,
  index,
  attachmentCount,
}: {
  attachment: Attachment;
  downloadSingleAttachment: Function;
  loadingHashes: LoadingHashes;
  index: number;
  attachmentCount: number;
}) => {
  const { t } = useTranslation();
  if (!attachment) return;

  const { type, name } = parseContentTypeHeader(
    attachment.headers['Content-Type'][0],
  );

  const attachmentFileName =
    name !== '' ? name : t('common.noAttachmentFileName');

  let fileExtension =
    attachmentFileName.indexOf('.') > -1
      ? attachmentFileName.split('.').pop()
      : '';

  return (
    <div data-testid={`attachment-container-${index}`}>
      {/* <div
        className={twMerge(
          'absolute left-0 top-0 z-20 flex h-full w-full flex-row items-center justify-center rounded-xl bg-white backdrop-blur-sm transition-all',
          loadingHashes[attachment.cid] ? 'visible' : 'invisible',
        )}
      >

      </div> */}
      <div className="flex w-full items-center justify-start gap-2.5 rounded-xl bg-white text-sm text-black transition-all">
        <div className="relative ml-[-12px] flex flex-row">
          <svg
            height="80px"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
          >
            <g>
              <path
                d="M576 102.4H268.8c-14.08 0-25.6 11.52-25.6 25.6v742.4c0 14.08 11.52 25.6 25.6 25.6h512c14.08 0 25.6-11.52 25.6-25.6V332.8L576 102.4z"
                className="fill-primary"
              ></path>
              <path
                d="M576 307.2c0 14.08 11.52 25.6 25.6 25.6h204.8L576 102.4v204.8z"
                className="fill-secondary"
              ></path>
            </g>
          </svg>
          <Text
            data-testid="attachment-extension"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-primary text-xs font-bold text-white"
          >
            {truncateString(fileExtension, 4)}
          </Text>
        </div>
        <div className="ml-[-10px] flex h-full flex-col justify-center text-left">
          <Tooltip>
            <TooltipTrigger asChild={true}>
              <Button
                data-testid="attachment-filename"
                variant="ghost"
                className="cursor-text p-0 hover:bg-white"
              >
                {truncateString(attachmentFileName, 20)}
              </Button>
            </TooltipTrigger>
            <TooltipContent
              data-testid="attachment-full-filename-tooltip"
              sideOffset={4}
            >
              {attachmentFileName}
            </TooltipContent>
          </Tooltip>
          <div className="mt-1 flex h-full w-full flex-row items-end justify-start gap-5 text-sm">
            <Text data-testid="attachment-size" className="mr-auto text-left">
              {attachment.len
                ? convertByteSizeToReadable(attachment.len)
                : 'N/A'}
            </Text>
          </div>
        </div>
        {attachmentCount > 1 && (
          <Tooltip>
            <TooltipTrigger asChild={true}>
              <Button
                disabled={loadingHashes[attachment.cid]}
                data-testid="download-attachment-button"
                variant="ghost"
                className="ml-auto print:hidden"
                onClick={() => downloadSingleAttachment(attachment)}
              >
                {loadingHashes[attachment.cid] ? (
                  <Spinner width={24} height={24} />
                ) : (
                  <DownloadIcon className="h-5 w-5 fill-primary-foreground group-hover:fill-primary-foreground" />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent
              data-testid="download-attachment-button-tooltip"
              sideOffset={4}
            >
              {t('common.downloadTooltip')}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default SingleAttachment;
