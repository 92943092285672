'use client';

import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PhoneInput from '@/app/_comps/PhoneInput/PhoneInput';

import ErrorToast from '@/app/_comps/Toast/ErrorToast/ErrorToast';
import SuccessToast from '@/app/_comps/Toast/SuccessToast/SuccessToast';
import {
  Button,
  Card,
  Checkbox,
  Headline,
  Input,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  Label,
  PadlockIcon,
  Text,
} from '@vereign/ui';
import { twMerge } from 'tailwind-merge';
import Link from '@/app/_comps/Link/Link';
import DoYouNeedHelpBox from '@/app/_comps/DoYouNeedHelpBox/DoYouNeedHelpBox';
import { ParsedPath, SEALAudience } from '@/app/_types/DataTypes';
import { LinkWithLocale, useTranslation } from 'next-export-i18n';
import useOtpFunctions from '@/app/_hooks/useOtpFunctions';
import useUserStore from '@/app/_stores/UserStore';
import useQRStore from '@/app/_stores/QRStore';
import { AuthClient } from '@/app/_clients/auth';
import { useRouter, useSearchParams } from 'next/navigation';
import { parseSEALUrl } from '@/app/_utils/SEALutils';
import { IpfsClient } from '@/app/_clients/ipfs';
import { assembleHashedEncryptedIPFSData } from '@/app/_services/SEALDataService/SEALDataService';
import { unicodeToHex } from '@/app/_utils/stringUtils';
import { useConfig } from '@/app/_hooks/useConfig';
import ResendSMS from '@/app/_comps/DoYouNeedHelpBox/ResendSMS';
import appRoutes from '@/app/appRoutes';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const FORM_STAGE = {
  initial: 1,
  OTP: 2,
};

const AuthScreen = () => {
  const { t } = useTranslation();
  const { config } = useConfig();
  const [stepOneFormState, setStepOneFormState] = useState({
    phone: '',
    email: '',
  });
  const router = useRouter();
  const searchParams = useSearchParams();
  const q = searchParams.get('q');

  const [retrySMSCount, setRetrySMSCount] = useState(0);
  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const [parsedPath, setParsedPath] = useState<ParsedPath>();
  const [formStage, setFormStage] = useState(FORM_STAGE.initial);

  const setUserEmail = useUserStore((state) => state.setAuthenticatedEmail);
  const userEmail = useUserStore((state) => state.authenticatedEmail);
  const audience = useQRStore((state) => state.audience);
  const setAudience = useQRStore((state) => state.setAudience);

  const emailInputRef = useRef<HTMLInputElement>(null);
  const otpInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!q) {
      return router.push('/invalid');
    }

    try {
      const pp = parseSEALUrl(q);
      setParsedPath(pp);
      setAudience(pp.audience);
    } catch (e) {
      router.push('/invalid');
      return;
    }
  }, [q, router, setAudience]);

  const ipfsClient = useMemo(() => {
    if (!config) {
      return;
    }

    return new IpfsClient({ ipfsAddr: config.ipfs.baseUrl, timeoutMs: 10000 });
  }, [config]);

  const authClient = useMemo(() => {
    if (!config) {
      return;
    }

    return new AuthClient(config.auth.baseUrl);
  }, [config]);

  const handleSendSMSRequest = useCallback(
    async (isResend: boolean) => {
      if (!parsedPath) {
        return router.push('/invalid');
      }

      try {
        if (
          typeof ipfsClient === 'undefined' ||
          typeof authClient === 'undefined'
        ) {
          return router.push('/invalid');
        }

        const tail = await ipfsClient.get(parsedPath.CID);
        const messageHash = await assembleHashedEncryptedIPFSData({
          head: parsedPath.head,
          tail,
        });
        const hex = unicodeToHex(messageHash);

        const data = await authClient.authUser({
          email: stepOneFormState.email,
          messageHash: hex,
          mobile: stepOneFormState.phone,
          language: searchParams.get('lang'),
        });

        if (
          (parsedPath.audience === SEALAudience.MULTIPLE &&
            data.email &&
            data.mobile) ||
          (parsedPath.audience === SEALAudience.SINGLE && data.mobile)
        ) {
          SuccessToast({
            message: t('common.toasts.smsMsg'),
          });
          if (!isResend) {
            setFormStage(FORM_STAGE.OTP);
          }
          return;
        }
      } catch (err) {
        ErrorToast({ message: 'Error logging in.' });
        return;
      }
    },
    [
      authClient,
      ipfsClient,
      parsedPath,
      searchParams,
      setFormStage,
      router,
      stepOneFormState,
      t,
    ],
  );

  async function handleInitialForm(e: FormEvent) {
    e.preventDefault();

    if (!parsedPath) {
      return router.push('/invalid');
    }

    if (!stepOneFormState.phone) {
      ErrorToast({ message: 'No phone number provided.' });
      console.log('No phone provided.');
      return;
    }

    if (
      !stepOneFormState.email &&
      parsedPath.audience === SEALAudience.MULTIPLE
    ) {
      ErrorToast({ message: 'No email provided.' });
      console.log('No email provided.');
      return;
    }

    await handleSendSMSRequest(false);
  }

  const handleResend = useCallback(() => {
    handleSendSMSRequest(true);
    setRetrySMSCount((prev) => prev + 1);

    return;
  }, [handleSendSMSRequest]);

  const {
    handleOTPForm,
    onOtpChange,
    otpValue,
    otpError,
    submitOtpStateDisabled,
    OTP_LENGTH,
  } = useOtpFunctions({
    onRequest: async (smsCode: string) => {
      if (typeof authClient === 'undefined') {
        router.push('/invalid');
        return;
      }

      const res = await authClient.verifySMSCode({
        smsCode,
        rememberMe: rememberMeChecked,
      });

      return res;
    },
    onOtpChangeSuccess: async () => {
      if (typeof authClient === 'undefined') {
        router.push('/invalid');
        return;
      }
      const res = await authClient.session();

      if (res.email) {
        setUserEmail(res.email);
      }
    },
  });

  const DoYouNeedHelpBoxMemo = useMemo(
    () => (
      <DoYouNeedHelpBox config={config}>
        <ResendSMS
          handleResendSms={handleResend}
          retryCount={retrySMSCount}
          t={t}
        />
      </DoYouNeedHelpBox>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      retrySMSCount,
      stepOneFormState?.email,
      stepOneFormState?.phone,
      userEmail,
    ],
  );

  const AssistanceBox = (
    <DoYouNeedHelpBox config={config}>
      <LinkWithLocale
        href={`${appRoutes.changeMobileNumber}`}
        className="text-sm font-medium text-primary underline"
        data-testid="change-phone-link"
      >
        {t('common.links.changeMobileNumber')}
      </LinkWithLocale>
    </DoYouNeedHelpBox>
  );

  if (!config) {
    return null;
  }

  return (
    <section
      data-testid="auth-page"
      className="flex w-full  max-w-screen-2xl flex-col gap-5 lg:flex-row"
    >
      <Card
        data-testid="auth-page-info-box"
        className={twMerge(
          'flex flex-1 animate-flip-in-from-left flex-col items-center gap-5 p-5 lg:p-8',
          formStage === FORM_STAGE.OTP && 'hidden lg:flex',
        )}
      >
        <div className="flex content-center items-center">
          <div className="mx-4 flex min-h-12 min-w-12 items-center justify-center rounded-lg bg-orange pl-1">
            <PadlockIcon className="flex h-6 w-6" />
          </div>
          <Headline
            data-testid="auth-page-info-headline"
            as="h2"
            className="text-2xl font-bold"
          >
            {t('pages.authPage.title')}
          </Headline>
        </div>

        <img
          data-testid="auth-page-info-image"
          src={t('pages.authPage.imageLink')}
          alt="verification code in phone messages"
          className="w-64  lg:w-auto"
        />
        <div>
          <Text
            data-testid="auth-page-info-text"
            as="p"
            className="text-center text-sm"
          >
            {t('pages.authPage.description')}
          </Text>
          <Text
            data-testid="auth-page-info-text"
            as="p"
            className="text-center text-sm"
          >
            {t('pages.authPage.descriptionAffix')}
          </Text>
        </div>
      </Card>

      {formStage === FORM_STAGE.initial && (
        <>
          <Card
            data-testid="auth-page-step-one-box"
            className="flex flex-[1.5] animate-flip-in-from-right flex-col items-center justify-center gap-5 p-8 text-center"
          >
            <div className="flex max-w-[400px] flex-1 flex-col justify-center gap-10">
              <div>
                <Text
                  data-testid="auth-page-step-one-info-text"
                  as="p"
                  className="font-bold"
                >
                  {audience === SEALAudience.SINGLE
                    ? t('pages.authPage.paragraphOnlyMobile')
                    : t('pages.authPage.paragraph')}
                </Text>
                <Text
                  data-testid="auth-page-step-one-info-text"
                  as="p"
                  className="font-bold"
                >
                  {t('pages.authPage.paragraphAffix')}
                </Text>
              </div>

              <form
                data-testid="auth-page-step-one-form"
                onSubmit={handleInitialForm}
                className="flex flex-col gap-4"
              >
                <PhoneInput
                  id="phone-input"
                  autoFocus
                  value={stepOneFormState.phone}
                  onChange={(phone) =>
                    setStepOneFormState((prev) => ({ ...prev, phone }))
                  }
                />
                {audience === SEALAudience.MULTIPLE && (
                  <Input
                    ref={emailInputRef}
                    data-testid="email-input"
                    type="email"
                    placeholder={t('common.placeholder.email')}
                    className="h-6 w-auto rounded-xl border-solid text-left text-base font-bold placeholder:font-bold placeholder:text-secondary-foreground"
                    value={stepOneFormState.email}
                    onChange={(e) =>
                      setStepOneFormState((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                )}
                <Button
                  data-testid="submit-form-button"
                  type="submit"
                  size="lg"
                  className="text-md min-w-32"
                >
                  {t('common.buttons.send')}
                </Button>
                <Link
                  className="font-bold"
                  href={t('common.links.privacyPolicy.link')}
                  external={true}
                  data-testid="privacy-policy-link"
                >
                  {t('common.links.privacyPolicy.label')}
                </Link>
              </form>
              <div
                className="hidden flex-col gap-3 text-left lg:flex"
                data-testid="assistance-box-web"
              >
                {AssistanceBox}
              </div>
            </div>
          </Card>
          <Card
            className="flex flex-col gap-3 p-8 text-left lg:hidden"
            data-testid="assistance-box-web"
          >
            {AssistanceBox}
          </Card>
        </>
      )}

      {formStage === FORM_STAGE.OTP && (
        <>
          <Card
            data-testid="auth-page-step-two-box"
            className="flex flex-[1.5] animate-flip-in-from-right flex-col items-center justify-center gap-5 p-8 text-center lg:p-16"
          >
            <Text data-testid="otp-info-text" as="p" className="mb-3">
              {t('pages.authPage.codeDescription', {
                number: formatPhoneNumberIntl(stepOneFormState.phone),
              })}
            </Text>

            <form onSubmit={handleOTPForm} className="flex flex-col gap-4">
              <InputOTP
                ref={otpInputRef}
                onChange={onOtpChange}
                autoFocus
                onErrorCapture={() => otpInputRef?.current?.focus()}
                maxLength={OTP_LENGTH}
                value={otpValue}
                containerClassName="gap-1 sm:gap-2 lg:gap-3 justify-center"
              >
                {Array.from({ length: OTP_LENGTH }).map((_, index) => (
                  <InputOTPGroup key={index}>
                    <InputOTPSlot
                      fakeCaretClassName="h-6 sm:h-10"
                      index={index}
                      className="h-16 w-12 text-2xl sm:h-28 sm:w-20 sm:text-5xl sm:first:rounded-l-xl sm:last:rounded-r-xl"
                    />
                  </InputOTPGroup>
                ))}
              </InputOTP>
              <Text
                as="p"
                className="min-h-5 text-sm font-semibold text-destructive-foreground"
                data-testid="otp-error"
              >
                {otpError && t(otpError)}
              </Text>
              <div className="flex flex-1 flex-col gap-5 sm:flex-row sm:gap-12">
                <div className="flex flex-1 flex-col gap-6">
                  <div className="flex flex-1 items-center gap-2.5">
                    <Checkbox
                      id="remember-me"
                      className="rounded-md"
                      checked={rememberMeChecked}
                      data-testid="remember-me-checkbox"
                      onCheckedChange={(checked) =>
                        setRememberMeChecked(
                          checked === 'indeterminate' ? false : checked,
                        )
                      }
                    />
                    <Label
                      htmlFor="remember-me"
                      data-testid="remember-me-checkbox-label"
                      className="text-start leading-tight"
                    >
                      {t('pages.authPage.codeCheckbox')}
                    </Label>
                  </div>
                  <Button
                    type="submit"
                    size="lg"
                    className="text-md min-w-32"
                    disabled={submitOtpStateDisabled}
                    data-testid="submit-otp-button"
                  >
                    {t('common.buttons.send')}
                  </Button>
                </div>
                <div
                  className="hidden flex-1 flex-col gap-3 text-left sm:flex"
                  data-testid="assistance-box-web"
                >
                  {DoYouNeedHelpBoxMemo}
                </div>
              </div>
            </form>
          </Card>
          <Card
            className="flex flex-col gap-3 p-8 text-left sm:hidden"
            data-testid="assistance-box-web"
          >
            {DoYouNeedHelpBoxMemo}
          </Card>
        </>
      )}
    </section>
  );
};

export default AuthScreen;
