import { AttachmentState } from '@/app/_types/DataTypes';
import {
  convertByteSizeToReadable,
  truncateString,
} from '@/app/_utils/stringUtils';
import {
  Button,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  X,
} from '@vereign/ui';
import { FileUploadState } from '@/app/_types/HooksTypes';
import Spinner from '../Spinner/Spinner';
import { twMerge } from 'tailwind-merge';

const FileDisplay = ({
  files,
  fileUploadState,
  onFileRemove,
  onFailUpload,
}: {
  files: AttachmentState[];
  fileUploadState: FileUploadState[];
  onFileRemove: (fileIndex: number) => void;
  onFailUpload: (fileName: string) => void;
}) => {
  return (
    <div
      data-testid="reply-files-list"
      className={twMerge(
        'mt-0 flex flex-row flex-wrap gap-[1%] font-bold',
        files?.length ? 'mb-4' : 'mb-0',
      )}
    >
      {files?.length
        ? files.map((f: AttachmentState, i: number) => {
            let selectedFileState = fileUploadState.filter((fState) => {
              return f.name === fState.fileName;
            });

            let selectedFileLoading = selectedFileState[0]
              ? selectedFileState[0].isLoading
              : false;

            if (selectedFileState[0]?.error) {
              onFailUpload(selectedFileState[0]?.fileName);
              return null;
            }

            return (
              <div
                key={f.name}
                data-testid={`reply-file-${i}`}
                className="mb-2 flex w-full flex-row items-center justify-between gap-2.5 rounded-xl bg-secondary p-3 px-6 lg:w-[49%]"
              >
                <Tooltip>
                  <TooltipTrigger asChild={true}>
                    <Text
                      data-testid={`reply-filename-${i}`}
                      className="text-l overflow-x-hidden whitespace-nowrap text-primary"
                    >
                      {truncateString(f.name, 20)}
                    </Text>
                  </TooltipTrigger>
                  <TooltipContent
                    data-testid={`reply-filename-tooltip-${i}`}
                    sideOffset={4}
                  >
                    {f.name}
                  </TooltipContent>
                </Tooltip>
                <Text
                  data-testid={`reply-file-size-${i}`}
                  className="mr-auto flex flex-row items-center whitespace-nowrap rounded-xl border-l-2 border-r-2 px-2.5 text-xs leading-6 text-primary-foreground"
                >
                  {f.size ? convertByteSizeToReadable(f.size) : 'N/A'}
                </Text>
                {selectedFileLoading ? (
                  <Spinner data-testid={`reply-file-loading-${i}`} width={16} />
                ) : (
                  <Button
                    data-testid={`reply-delete-file-${i}`}
                    type="button"
                    onClick={() => onFileRemove(i)}
                  >
                    <X className="h-4 w-4 fill-white" />
                  </Button>
                )}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default FileDisplay;
