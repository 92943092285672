import React, { useMemo } from 'react';
import GeneratedAvatar from '@/app/_comps/GeneratedAvatar';
import { Participant } from '@/app/_types/DataTypes';
import { Card, CardHeader, Text } from '@vereign/ui';
import RecipientsBox from './Recipients/RecipientsBox';
import { useTranslation } from 'next-export-i18n';
import { Config } from '@/app/_config/schema';
import useQRStore from '@/app/_stores/QRStore';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

const EmailMeta = ({ config }: { config: Config }) => {
  const qrCodeData = useQRStore((state) => state.QR);
  const { t } = useTranslation();

  let qrDataDate: Date | string = '';
  if (qrCodeData) {
    qrDataDate = new Date(qrCodeData.dateOfSending)
      .toLocaleString('en-GB', dateOptions)
      .replaceAll('/', '.');
  }

  let sender: Participant | undefined;
  if (qrCodeData && qrCodeData.sender) {
    sender = qrCodeData.sender;
  }

  const recipients = useMemo(() => {
    let recip: any[] = [];
    let ccRecip: any[] = [];

    if (qrCodeData?.recipients?.to) {
      recip = [...qrCodeData.recipients.to];
    }

    if (qrCodeData?.recipients?.cc) {
      ccRecip = [...qrCodeData.recipients.cc];
    }

    return {
      to: recip,
      cc: ccRecip,
    };
  }, [qrCodeData]);

  return (
    <Card>
      <CardHeader
        id="email-meta"
        data-testid="email-meta-box"
        className="block gap-4 space-y-0 lg:flex lg:flex-row lg:items-stretch lg:justify-between lg:gap-8 lg:p-8"
      >
        <div className="mb-5 flex flex-row items-start gap-5 lg:mb-0 lg:flex-[2]">
          <div
            data-testid="avatar-container"
            className="relative flex h-16 w-16 min-w-16 flex-row items-center justify-center lg:h-24 lg:w-24 lg:min-w-24"
          >
            <GeneratedAvatar
              config={config}
              email={sender?.email}
              personName={sender?.name}
            />
          </div>
          <div className="flex flex-col items-start text-left">
            <Text
              data-testid="date-sent"
              className="text-sm font-bold text-primary-foreground lg:mb-2"
            >
              {qrDataDate || t('common.noDate')}
            </Text>
            <Text
              data-testid="from-text"
              as="p"
              className="hidden text-sm font-bold lg:flex"
            >
              {sender ? t('common.from') : ''}
            </Text>
            <Text
              data-testid="sender-name"
              as="p"
              className="text-xl font-bold"
            >
              {sender?.name}
            </Text>
            <Text
              data-testid="sender-email"
              as="p"
              className="text-sm text-primary-foreground"
            >
              {sender?.email}
            </Text>
          </div>
        </div>
        <div className="block flex-row items-stretch lg:flex lg:flex-1">
          <RecipientsBox recipients={recipients} />
        </div>
      </CardHeader>
    </Card>
  );
};

export default EmailMeta;
