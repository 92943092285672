export class IpfsError extends Error {
  code: string;

  constructor(message: string, code: string) {
    super(message);
    this.code = code;
  }
}

interface IpfsClientOptions {
  ipfsAddr: string;
  timeoutMs: number;
}

export class IpfsClient {
  private ipfsAddr: string;
  private timeoutMs: number;

  constructor({ ipfsAddr, timeoutMs }: IpfsClientOptions) {
    this.ipfsAddr = ipfsAddr;
    this.timeoutMs = timeoutMs;
  }

  public async get(cid: string): Promise<Uint8Array> {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, this.timeoutMs);

    try {
      const response = await fetch(
        `${this.ipfsAddr}/${cid}?stream-channels=false`,
        {
          method: 'GET',
          signal: controller.signal,
        },
      );

      if (!response.ok) {
        throw new IpfsError(
          `Failed to fetch content: ${response.statusText}`,
          'IPFS_FETCH_ERROR',
        );
      }

      const buffer = await response.arrayBuffer();
      return new Uint8Array(buffer);
    } catch (err) {
      if ((err as Error).name === 'AbortError') {
        throw new IpfsError('Request timed out', 'IPFS_TIMEOUT');
      } else {
        const error = err as IpfsError;
        if (!error.code) {
          error.code = 'IPFS_UNKNOWN_ERROR';
        }
        throw error;
      }
    } finally {
      clearTimeout(timeoutId);
    }
  }
}
