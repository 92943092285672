import { useEffect, useMemo, useRef } from 'react';
import useUserStore from '../_stores/UserStore';
import { AuthClient } from '../_clients/auth';
import { useConfigStore } from '../_stores/AppConfigStore';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useConfigStore((state) => state.config);
  const setUserEmail = useUserStore((state) => state.setAuthenticatedEmail);
  const authClient = useMemo(
    () => (config ? new AuthClient(config.auth.baseUrl) : null),
    [config],
  );
  const isRequested = useRef(false);

  useEffect(() => {
    if (!authClient || !config || isRequested.current) {
      return;
    }

    const fetchSession = async () => {
      try {
        const fetchedSession = await authClient.session();
        if (fetchedSession.email) {
          setUserEmail(fetchedSession.email);
        }
        isRequested.current = true;
      } catch (error) {
        //TODO: get the error from our backend and translate it
        //TODO: remove the log
        console.error('Session fetch error:', error);
      }
    };

    fetchSession();
  }, [authClient, setUserEmail, config]);

  return children;
};
