import { create } from 'zustand';

interface UserState {
  authenticatedEmail: string | null;
  setAuthenticatedEmail: (_a: string | null) => void;
  replyOpen: boolean;
  setReplyOpen: (_a: boolean) => void;
  isSettingsOpen: boolean;
  setIsSettingsOpen: (_a: boolean) => void;
}

const useUserStore = create<UserState>((set) => ({
  authenticatedEmail: null,
  setAuthenticatedEmail: (_a) => set((state) => ({ authenticatedEmail: _a })),

  replyOpen: false,
  setReplyOpen: (_a) => set((state) => ({ replyOpen: _a })),

  isSettingsOpen: false,
  setIsSettingsOpen: (_a) => set((state) => ({ isSettingsOpen: _a })),
}));

export default useUserStore;
