import React, { useCallback, useEffect, useState } from 'react';
import { emailHash } from '../../_utils/stringUtils';
import { getAvatarSrc } from '@/app/_utils/avatarUtils';
import GeneratedAvatar from './GeneratedAvatar';
import { Config } from '@/app/_config/schema';
import useQRStore from '@/app/_stores/QRStore';

interface Props {
  email?: string;
  personName?: string;
  className?: string;
  config: Config;
}

const GeneratedAvatarContainer = ({
  email,
  personName,
  className,
  config,
}: Props) => {
  const [avatarLoading, setAvatarLoading] = useState<boolean>(true);
  const gravSrc = useQRStore((state) => state.senderImage);
  const setGravSrc = useQRStore((state) => state.setSenderImage);

  const handleAvatar = useCallback(
    async ({
      config,
      email,
      name,
    }: {
      config: Config;
      email?: string;
      name?: string;
    }) => {
      try {
        if (typeof gravSrc === 'undefined') {
          setAvatarLoading(true);
          const hash = emailHash(email);
          const avatarSrc = await getAvatarSrc(hash, config, email, name);
          setGravSrc(avatarSrc);
        }
      } catch (err) {
        // console.error('Error fetching avatar:', err);
      } finally {
        setAvatarLoading(false);
      }
    },
    [gravSrc, setAvatarLoading, setGravSrc], // Dependencies
  );

  useEffect(() => {
    handleAvatar({ config, email, name: personName });
  }, [email, personName, config, handleAvatar]);

  return (
    <GeneratedAvatar
      email={email}
      name={personName}
      isLoading={avatarLoading}
      imgSrc={gravSrc}
      className={className}
    />
  );
};

export default GeneratedAvatarContainer;
