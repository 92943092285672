import {
  BackIcon,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@vereign/ui';
import React from 'react';
import Circle from './Circle';
import { twMerge } from 'tailwind-merge';

export interface StepperProps {
  stepsData: {
    header: React.ReactNode;
    content: React.ReactNode;
    onBackButtonClick?: () => void;
  }[];
  totalSteps: number;
  currentStepIdx: number;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  backButtonClassName?: string;
}

function Stepper({
  stepsData,
  totalSteps,
  currentStepIdx,
  className,
  headerClassName,
  contentClassName,
  footerClassName,
  backButtonClassName,
}: StepperProps) {
  const { header, content, onBackButtonClick } = stepsData[currentStepIdx];

  return (
    <Card className={className}>
      <CardHeader
        className={twMerge('relative flex flex-row gap-4', headerClassName)}
      >
        {onBackButtonClick && (
          <Button
            variant="round"
            size="icon"
            className={twMerge(
              'min-h-12 min-w-12 bg-primary',
              backButtonClassName,
            )}
            onClick={onBackButtonClick}
          >
            <BackIcon className="h-4 w-4" />
          </Button>
        )}
        {header}
      </CardHeader>
      <CardContent className={contentClassName}>{content}</CardContent>
      <CardFooter
        className={twMerge(
          'flex justify-center gap-2 align-middle',
          footerClassName,
        )}
      >
        {Array.from({ length: totalSteps }).map((_, index) => {
          return <Circle key={index} filled={currentStepIdx > index - 1} />;
        })}
      </CardFooter>
    </Card>
  );
}

export default Stepper;
