import { ParsedPath } from '../_types/DataTypes';
import { Participant, SEALAudience } from '../_types/DataTypes';
import {
  base64ToByte,
  decodeBase64URL,
  isValueInStringEnum,
} from './stringUtils';
import { mimeWordsDecode } from 'emailjs-mime-codec';

export const MIME_EMAIL_REGEX = /\S+@\S+/g;
export const MIME_HEADER_SYMBOLS_REGEX = /[<>"]/g;
export const IPFSCIDRegEx = /^Qm[1-9A-HJ-NP-Za-km-z]{44}$/g;

export const parseMimeParticipants = (
  participantsHeaderValue: string[] | undefined,
): Participant[] => {
  if (!participantsHeaderValue || participantsHeaderValue.length < 1) return [];

  return participantsHeaderValue
    .join(',')
    .split(',')
    .map((participantString) => {
      const match = participantString.match(MIME_EMAIL_REGEX);
      /* istanbul ignore else */
      if (match?.length) {
        const email = match[0].replace(MIME_HEADER_SYMBOLS_REGEX, '');
        const name = participantString
          .replace(email, '')
          .replace(MIME_HEADER_SYMBOLS_REGEX, '')
          .trim();

        const decodedName = mimeWordsDecode(name);

        return {
          name: decodedName,
          email,
        };
      } else {
        return {
          name: '',
          email: participantString,
        };
      }
    });
};

export const parseSEALUrl = (url: string | null) => {
  // INFO: [STRUCTURE]
  // first 2 bytes -> {p,m,s}-
  // next 32 -> key
  // next 12 -> iv
  // next 46 -> CID
  // rest -> head

  if (!url) {
    throw new Error('No URL provided.');
  }

  const AUDIENCE_LEN = 2;
  const HEAD_LEN = 26;
  const IV_LEN = 12;
  const CID_LEN = 46;

  const MIN_LEN = AUDIENCE_LEN + HEAD_LEN + IV_LEN + CID_LEN;

  let base64 = decodeBase64URL(url);
  let decodedBuffer = base64ToByte(base64);

  if (decodedBuffer.byteLength < MIN_LEN) {
    throw new Error('Invalid SEAL URL LEN.');
  }

  let currentLenOffset = AUDIENCE_LEN;

  const audience = decodedBuffer[0];

  const iv = decodedBuffer.slice(
    currentLenOffset,
    (currentLenOffset += IV_LEN),
  );
  const CID = decodedBuffer.slice(
    currentLenOffset,
    (currentLenOffset += CID_LEN),
  );
  const head = decodedBuffer.slice(
    currentLenOffset,
    (currentLenOffset += HEAD_LEN),
  );
  const key = decodedBuffer.slice(currentLenOffset, decodedBuffer.byteLength);
  const audienceString = String.fromCharCode(audience);
  const CIDString = String.fromCharCode(...new Uint8Array(CID));
  let validCID = CIDString.startsWith('Qm');

  if (
    !isValueInStringEnum(SEALAudience)(audienceString) ||
    head.length !== 26 ||
    iv.length !== 12 ||
    CIDString.length !== 46 ||
    !validCID ||
    !key
  ) {
    throw new Error('Invalid SEAL URL.');
  }

  return {
    audience: audienceString,
    key: key,
    iv: iv,
    CID: CIDString,
    head: head,
  } as ParsedPath;
};

export const parseContentTypeHeader = (typeHeader: string) => {
  const valueArray = typeHeader.split(';');
  let type = valueArray[0].trim();
  // find the element that starts with name= inside value array
  const headerNameElement = valueArray.find((element) =>
    element.trim().startsWith('name='),
  );
  const headerName = headerNameElement
    ? headerNameElement.trim().replace('name=', '').replace(/['"]+/g, '')
    : '';

  let decodedName = mimeWordsDecode(headerName);

  return {
    type,
    name: decodedName,
  };
};

export const parseMessageIdHeader = (idHeader: string) => {
  return idHeader.replace(/[<>]+/g, '');
};

export const parseContentTypePlainHeader = (typeHeader: string) => {
  const valueArray = typeHeader.split(';');
  let type = valueArray[0].trim();
  let charset: string = valueArray[1].trim();

  if (!charset.startsWith('charset')) {
    charset = '';
  } else {
    charset = charset.split('=')[1].replace(/['"]+/g, '');
  }

  return {
    type,
    charset,
  };
};

export const getRecipientsAsText = (recipients: Participant[]) => {
  return recipients
    .map((recipient) =>
      recipient.name
        ? `${recipient.name} ${recipient.email}`
        : `${recipient.email}`,
    )
    .join(', ');
};
