import 'react-phone-number-input/style.css';
import ReactPhoneInput from 'react-phone-number-input';
import { twMerge } from 'tailwind-merge';
import React, { LegacyRef } from 'react';
import { Input, InputProps } from '@vereign/ui';
import CountrySelect from './CountrySelect';
import { useTranslation } from 'next-export-i18n';

interface PhoneInputProps {
  id?: string;
  autoFocus?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
  const input = event.target as HTMLInputElement;
  const length = input.value.length;

  input.setSelectionRange(length, length);
};

const InputComponent = React.forwardRef(function InputComponent(
  { id, value, onChange, autoFocus, className, ...restProps }: InputProps,
  ref: LegacyRef<HTMLInputElement>,
) {
  const { t } = useTranslation();
  return (
    <Input
      ref={ref}
      id={id}
      data-testid="phone-input"
      placeholder={t('common.placeholder.phone')}
      value={value}
      autoFocus={autoFocus}
      onChange={onChange}
      className={twMerge(
        'h-6 flex-1 rounded-xl rounded-bl-none rounded-tl-none border-2 border-solid border-secondary-foreground px-3 py-2',
        'text-left text-base font-bold text-primary-foreground placeholder:text-secondary-foreground',
        'focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0',
      )}
      {...restProps}
    />
  );
});

const PhoneInput: React.FC<PhoneInputProps> = ({
  id,
  value,
  onChange,
  autoFocus,
}) => {
  return (
    <ReactPhoneInput
      id={id}
      autoFocus={autoFocus}
      defaultCountry="CH"
      value={value}
      addInternationalOption={false}
      onChange={(phone) => {
        onChange(phone ?? '');
      }}
      onFocus={handleFocus}
      inputComponent={InputComponent}
      countrySelectComponent={CountrySelect}
    />
  );
};

export default PhoneInput;
