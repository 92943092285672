import { Participant } from '@/app/_types/DataTypes';
import { twMerge } from 'tailwind-merge';
import { Text } from '@vereign/ui';

interface RecipientsBoxProps extends Participant {
  index: number;
  skipBorder?: boolean;
}

const Recipient = ({ name, email, skipBorder, index }: RecipientsBoxProps) => (
  <div
    data-testid={`recipient-${index}`}
    className={twMerge(
      'my-1',
      !skipBorder && 'border-spacing-x-0.5 border-t border-dashed',
    )}
  >
    {name && (
      <Text
        data-testid={`recipient-name-${index}`}
        className="mr-2 inline-block whitespace-nowrap text-sm font-bold"
      >
        {name}
      </Text>
    )}
    {email && (
      <Text
        data-testid={`recipient-email-${index}`}
        className="inline-block text-xs font-normal text-primary-foreground"
      >
        {email}
      </Text>
    )}
  </div>
);

export default Recipient;
