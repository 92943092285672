import { Dispatch, DragEvent, SetStateAction, useState } from 'react';
import {
  dropHandler,
  handleFileUploadRequest,
  validateFilesAndCompare,
} from '../_utils/formUtils';
import {
  GenericResponse,
  IntSEALObject,
  ReplyMailData,
} from '../_types/DataTypes';
import Queue from 'queue-promise';
import { FileUploadState } from '../_types/HooksTypes';
import { AuthClient } from '../_clients/auth';
import ErrorToast from '../_comps/Toast/ErrorToast/ErrorToast';
import { useConfig } from './useConfig';

//TODO: why do we need a queue with concurrent 1 ??
//TODO: can we use somethi like promise all, allSettled or something
//TODO: this looks really not good
export default function useFilesUpload(authClient: AuthClient) {
  const [fileUploadState, setFileUploadState] = useState<FileUploadState[]>([]);
  const { config } = useConfig();

  if (!config) return [];

  const Q = new Queue({
    concurrent: 1,
  });

  async function handleFileDrop(
    ev: DragEvent<HTMLDivElement>,
    setDragOver: Dispatch<SetStateAction<boolean>>,
    mailData: ReplyMailData,
    setMailData: Dispatch<SetStateAction<ReplyMailData>>,
    qr: IntSEALObject | undefined,
    t: any,
    setFileValidations?: (validations: GenericResponse[]) => void,
  ) {
    ev.preventDefault();
    setDragOver(false);

    if (!qr) {
      console.log('no qr state');
      return;
    }

    const res = dropHandler(
      ev,
      mailData.attachments,
      config!.general.maxSingleFileSizeMb,
    );

    /* istanbul ignore next */
    if (!res) {
      return;
    }

    const { files, reject, resArray } = res;

    /* istanbul ignore next */
    if (reject) {
      setFileValidations?.(resArray);
      return;
    }

    files.forEach(async (file) => {
      setFileUploadState((prev) => {
        return [
          ...prev,
          {
            fileName: file.name,
            isLoading: true,
            error: null,
          },
        ];
      });

      Q.enqueue(async () => {
        return await handleFileUploadRequest({
          file,
          qr,
          authClient,
        });
      });
    });

    Q.on('reject', (err) => {
      let { fileName, message } = err;
      if (!err.fileName) {
        console.log('Unexpected error format');
        fileName = message;
      }

      ErrorToast({ message: t('errors.files.upload', { fileName }) });
      setFileUploadState((prev) => {
        const idx = prev.findIndex((f) => f.fileName === fileName);
        if (idx !== -1) {
          return [
            ...prev.slice(0, idx),
            {
              ...prev[idx],
              isLoading: false,
              error: message || 'Unknown error',
            },
            ...prev.slice(idx + 1),
          ];
        }
        return prev;
      });
    });

    Q.on('resolve', (data) => {
      if (!data) return;
      return setFileUploadState((prev) => {
        const idx = prev.findIndex((f) => f.fileName === data.filename);
        if (idx !== -1) {
          return [
            ...prev.slice(0, idx),
            {
              ...prev[idx],
              isLoading: false,
              error: data.error ? data.error.message : null,
            },
            ...prev.slice(idx + 1),
          ];
        }
        return prev;
      });
    });

    const fileMetaArray = files.map(({ name, size }) => ({ name, size }));

    setMailData((prev) => ({
      ...prev,
      attachments: [...prev.attachments, ...fileMetaArray],
    }));
  }

  function handleFileInput(
    e: React.ChangeEvent<HTMLInputElement>,
    outsideStateSetter: Dispatch<SetStateAction<ReplyMailData>>,
    outsideState: ReplyMailData,
    outsideValidationStateSetter: Dispatch<SetStateAction<GenericResponse[]>>,
    qr: IntSEALObject | undefined,
    t: any,
  ) {
    let fileArray = e.target.files;
    if (!fileArray || fileArray.length < 1) {
      return;
    }
    let f = Array.from(fileArray);

    let { reject, resArray } = validateFilesAndCompare(
      outsideState.attachments,
      f,
      config!.general.maxSingleFileSizeMb,
    );

    // INFO: test case covered by validateFilesAndCompare
    /* istanbul ignore next */
    if (reject) {
      outsideValidationStateSetter(resArray);
      return {
        success: false,
        message: 'File/s did not pass validation.',
      };
    }

    if (!qr) {
      console.log('no user or qr state');
      return;
    }

    f.forEach(async (file) => {
      setFileUploadState((prev) => {
        return [
          ...prev,
          {
            fileName: file.name,
            isLoading: true,
            error: null,
          },
        ];
      });

      Q.enqueue(async () => {
        return await handleFileUploadRequest({
          file,
          qr,
          authClient,
        });
      });
    });

    Q.on('reject', (err) => {
      let { fileName, message } = err;
      if (!err.fileName) {
        console.log('Unexpected error format');
        fileName = message;
      }

      //TODO: if the error is from auth service there will be a errorCode field
      //TODO check it and translate the proper error message

      ErrorToast({ message: t('errors.files.upload', { fileName }) });
      setFileUploadState((prev) => {
        const idx = prev.findIndex((f) => f.fileName === fileName);
        if (idx !== -1) {
          return [
            ...prev.slice(0, idx),
            {
              ...prev[idx],
              isLoading: false,
              error: message || 'Unknown error',
            },
            ...prev.slice(idx + 1),
          ];
        }
        return prev;
      });
    });

    Q.on('resolve', (data) => {
      if (!data) return;
      return setFileUploadState((prev) => {
        const idx = prev.findIndex((f) => f.fileName === data.filename);
        if (idx !== -1) {
          return [
            ...prev.slice(0, idx),
            {
              ...prev[idx],
              isLoading: false,
              error: data.error ? data.error.message : null,
            },
            ...prev.slice(idx + 1),
          ];
        }
        return prev;
      });
    });

    const fArr = f.map((el) => {
      return {
        name: el.name,
        size: el.size,
        type: el.type,
      };
    });

    outsideStateSetter((prev) => ({
      ...prev,
      attachments: [...prev.attachments, ...fArr],
    }));
  }

  return [
    fileUploadState,
    handleFileDrop,
    handleFileInput,
    setFileUploadState,
  ] as const;
}
