import PhoneInput from '@/app/_comps/PhoneInput/PhoneInput';
import Stepper from '@/app/_comps/Stepper/Stepper';
import {
  Button,
  Headline,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  Label,
  Text,
} from '@vereign/ui';
import React, { Dispatch, FormEvent, RefObject, SetStateAction } from 'react';

export interface ChangePhoneProps {
  t: any;
  handleNewPhoneSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  firstSubmitButtonRef: RefObject<HTMLButtonElement>;
  setCurrentStepIdx: Dispatch<SetStateAction<number>>;
  handleOTPForm: (e: FormEvent) => Promise<void>;
  formattedPhone: string;
  onOtpChange: (otp: string) => void;
  OTP_LENGTH: number;
  otpError: string | undefined;
  submitOtpStateDisabled: boolean;
  currentStepIdx: number;
  CHANGE_PHONE_STEPS: {
    NEW_PHONE: number;
    OTP: number;
    SUCCESS: number;
  };
  CHANGE_PHONE_STEPS_COUNT: number;
}

function ChangePhone({
  t,
  handleNewPhoneSubmit,
  phone,
  setPhone,
  firstSubmitButtonRef,
  setCurrentStepIdx,
  handleOTPForm,
  formattedPhone,
  onOtpChange,
  OTP_LENGTH,
  otpError,
  submitOtpStateDisabled,
  currentStepIdx,
  CHANGE_PHONE_STEPS,
  CHANGE_PHONE_STEPS_COUNT,
}: ChangePhoneProps) {
  return (
    <Stepper
      stepsData={[
        {
          header: (
            <div className="flex flex-col gap-2">
              <Headline as="h4" className="font-bold">
                {t('pages.settingsPage.changeNumber')}
              </Headline>
              <p className="font-s">
                {t('pages.settingsPage.changeNumberDescription')}
              </p>
            </div>
          ),
          content: (
            <form
              data-testid="auth-page-step-one-form"
              onSubmit={handleNewPhoneSubmit}
              className="flex flex-col gap-4"
            >
              <Label htmlFor="phone-input" className="text-base font-thin">
                {t('pages.settingsPage.newPhone')}
              </Label>
              <PhoneInput
                id="phone-input"
                value={phone}
                onChange={(value) => setPhone(value)}
              />
              <Button
                ref={firstSubmitButtonRef}
                type="submit"
                className="w-full max-w-60 self-center"
              >
                {t('common.buttons.next')}
              </Button>
            </form>
          ),
        },
        {
          onBackButtonClick: () =>
            setCurrentStepIdx(CHANGE_PHONE_STEPS.NEW_PHONE),
          header: (
            <Headline as="h4" className="font-bold">
              {t('pages.settingsPage.changeNumber')}
            </Headline>
          ),
          content: (
            <form onSubmit={handleOTPForm} className="flex flex-col gap-4">
              <Text as="p">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('pages.settingsPage.confirmMessage', {
                      phoneNumber: `<span class="font-bold text-primary">${formattedPhone}</span>`,
                    }),
                  }}
                ></span>
              </Text>
              <Text as="p">
                {t('pages.settingsPage.verificationCodeLabel')}
              </Text>
              <InputOTP
                onChange={onOtpChange}
                autoFocus
                maxLength={OTP_LENGTH}
                containerClassName="gap-1 sm:gap-2 justify-center"
              >
                {Array.from({ length: OTP_LENGTH }).map((_, index) => (
                  <InputOTPGroup key={index}>
                    <InputOTPSlot
                      fakeCaretClassName="h-6 lg:h-10"
                      index={index}
                      className="h-16 w-12 text-2xl lg:h-24 lg:w-16 lg:text-5xl lg:first:rounded-l-xl lg:last:rounded-r-xl"
                    />
                  </InputOTPGroup>
                ))}
              </InputOTP>
              <Text
                as="p"
                className="min-h-5 text-center text-sm font-semibold text-destructive-foreground"
                data-testid="otp-error"
              >
                {otpError && t(otpError)}
              </Text>
              <Button
                type="submit"
                size="lg"
                className="text-md w-full max-w-60 self-center"
                disabled={submitOtpStateDisabled}
                data-testid="submit-otp-button"
              >
                {t('common.buttons.next')}
              </Button>
            </form>
          ),
        },
        {
          header: (
            <Headline as="h4" className="font-bold">
              {t('pages.settingsPage.changeNumber')}
            </Headline>
          ),
          content: (
            <Text as="p">
              <span
                dangerouslySetInnerHTML={{
                  __html: t('pages.settingsPage.successMessage', {
                    phoneNumber: `<span class="font-bold text-primary">${formattedPhone}</span>`,
                  }),
                }}
              ></span>
            </Text>
          ),
        },
      ]}
      totalSteps={CHANGE_PHONE_STEPS_COUNT}
      currentStepIdx={currentStepIdx}
    />
  );
}

export default ChangePhone;
