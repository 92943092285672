import { FormEvent, useState } from 'react';
import ErrorToast from '@/app/_comps/Toast/ErrorToast/ErrorToast';
import { useConfig } from './useConfig';
import {
  VerifyNewMobileSMSCodeResponse,
  VerifySMSCodeResponse,
} from '../_clients/auth';
import { useTranslation } from 'next-export-i18n';

const OTP_LENGTH = 6;

const otpErrorMessages = {
  emptyOTP: 'No code provided.',
  invalidOtpLength: 'The code should consist of 6 digits.',
  invalidCode: 'errors.incorrectCode',
};

interface AdditionalOtpRequestParams {
  rememberMe?: boolean;
}

interface OtpFunctionsProps {
  // requestPath: string;
  onOtpChangeSuccess: () => void;
  // additionalRequestParams?: AdditionalOtpRequestParams;
  onRequest: (
    otpValue: string,
  ) =>
    | Promise<VerifySMSCodeResponse | undefined>
    | Promise<VerifyNewMobileSMSCodeResponse>
    | Promise<void>;
}

export default function useOtpFunctions({
  onRequest,
  onOtpChangeSuccess,
}: OtpFunctionsProps) {
  const [otpValue, setOtpValue] = useState<string | undefined>();
  const [otpError, setOtpError] = useState<string | undefined>();
  const [submitOtpStateDisabled, setSubmitOtpStateDisabled] = useState(true);
  const { t } = useTranslation();
  const { config } = useConfig();

  const onOtpChange = (otp: string) => {
    setOtpValue(otp);
    if (otp.length === OTP_LENGTH) {
      setSubmitOtpStateDisabled(false);
    } else {
      setSubmitOtpStateDisabled(true);
    }
  };

  async function handleOTPForm(e: FormEvent) {
    e.preventDefault();

    if (!otpValue) {
      setOtpError(otpErrorMessages.emptyOTP);
      ErrorToast({ message: 'No One Time Password provided.' });
      return;
    }
    if (otpValue.length !== OTP_LENGTH) {
      setOtpError(otpErrorMessages.invalidOtpLength);
      ErrorToast({ message: 'Invalid One Time Password length.' });
      return;
    }

    setSubmitOtpStateDisabled(true);

    if (!config) {
      return;
    }

    try {
      await onRequest(otpValue);
      setOtpValue('');
      setOtpError(undefined);
      onOtpChangeSuccess();
      return;
    } catch (err) {
      setOtpValue('');
      setOtpError(otpErrorMessages.invalidCode);
      ErrorToast({ message: t('toasts.invalidCode') });
      setSubmitOtpStateDisabled(false);
      return;
    }
  }

  return {
    OTP_LENGTH,
    onOtpChange,
    handleOTPForm,
    otpValue,
    otpError,
    submitOtpStateDisabled,
  };
}
