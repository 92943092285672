import { Config } from '../_config/schema';

export interface IPFSDataRequest {
  key: Uint8Array;
  iv: Uint8Array;
  head: Uint8Array;
  tail: Uint8Array | '';
  ipfsVersion?: undefined | 'v2';
  objectToDecrypt?: any;
  encoding?: string;
}

export interface ExtSEALObject {
  headers: any;
  sealHash: string;
  dateOfSealing: number;
  plain?: {
    cid: string;
    head: string;
    tail: Uint8Array | string;
    headers: any;
    key: string;
    len: number;
    nonce: string;
    type: 'text/plain';
  };
  html?: {
    cid: string;
    head: string;
    tail: Uint8Array | string;
    headers: any;
    key: string;
    len: number;
    nonce: string;
    type: string;
  };
  senderDID?: string;
  senderVC?: any;
  attachments?: Attachment[];
}

export interface IntSEALObject {
  sender: Participant;
  subject: string;
  messageId: string | undefined;
  sealHash: string;
  recipients: {
    to: Participant[];
    cc?: Participant[];
  };
  dateOfSending: number | string | Date;
  dateOfSealing: number;
  plain?: {
    content?: string;
    len?: number;
    type?: string;
  };
  html?: {
    content?: string;
    len?: number;
    type?: string;
  };
  senderDID?: string;
  senderVC?: any;
  attachments?: Attachment[];
  replyTo?: string;
}

export interface Attachment {
  cid: string;
  head: string;
  tail: Uint8Array | string;
  headers: any;
  key: string;
  len: number;
  nonce: string;
  type: string;
}

export interface Participant {
  email: string;
  name?: string;
}

// INFO:
// p = public SEAL (no authentication)
// m = multiple authenticated recipients
// s = single authenticated recipients
export enum SEALAudience {
  PUBLIC = 'p',
  MULTIPLE = 'm',
  SINGLE = 's',
}
export type AudienceOptions =
  | SEALAudience.SINGLE
  | SEALAudience.MULTIPLE
  | SEALAudience.PUBLIC;

export interface ParsedPath {
  audience: AudienceOptions;
  key: Uint8Array;
  iv: Uint8Array;
  CID: string;
  head: Uint8Array;
}

export interface AttachmentState {
  name: string;
  size?: number;
  type?: string;
}

export interface ReplyMailData {
  sender: string;
  body: string;
  attachments: AttachmentState[];
}

export interface GenericResponse {
  success: boolean;
  message: string;
  data?: any;
}
