import { Button, ButtonProps, ReplyIcon } from '@vereign/ui';
import { twMerge } from 'tailwind-merge';

interface ReplyButtonProps extends ButtonProps {
  scrollToId?: string;
}

const ReplyButton = ({
  children,
  onClick,
  size = 'default',
  type = 'button',
  className,
  scrollToId,
  ...restProps
}: ReplyButtonProps) => {
  const content = (
    <>
      <ReplyIcon
        title="reply icon"
        className="mr-2 h-5 w-5 fill-white group-hover:fill-primary"
      />
      {children}
    </>
  );

  return (
    <Button
      variant="default"
      size={size}
      type={type}
      className={twMerge('text-md gap-1 px-8 font-bold lg:min-w-48', className)}
      onClick={onClick}
      asChild={!!scrollToId}
      {...restProps}
    >
      {scrollToId ? <a href={`#${scrollToId}`}>{content}</a> : content}
    </Button>
  );
};

export default ReplyButton;
