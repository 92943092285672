'use client';
import {
  GlobeIcon,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@vereign/ui';
import i18n from '../../../i18n';
import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import { useTranslation } from 'next-export-i18n';
import { AuthClient } from '@/app/_clients/auth';
import { useConfig } from '@/app/_hooks/useConfig';
import ErrorToast from '../Toast/ErrorToast/ErrorToast';

const languageOptions = [
  {
    value: 'de',
    label: 'locales.de',
  },
  {
    value: 'en',
    label: 'locales.en',
  },
  {
    value: 'fr',
    label: 'locales.fr',
  },
  {
    value: 'it',
    label: 'locales.it',
  },
];

interface LanguageSelectProps {
  sendRequestOnChange?: boolean;
  hideIconOnMobile?: boolean;
}

const LanguageSelect = ({
  sendRequestOnChange,
  hideIconOnMobile,
}: LanguageSelectProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const langParam = searchParams.get('lang') || i18n.defaultLang;
  const [lang, setLang] = useState(langParam);
  const { t } = useTranslation();
  const { config } = useConfig();

  const searchParamsString = searchParams.toString();

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParamsString);
      params.set(name, value);
      return params.toString();
    },
    [searchParamsString],
  );

  if (!config) {
    return null;
  }

  const authClient = new AuthClient(config.auth.baseUrl);

  async function sendOnChangeLangRequestAsync(lang: string | null) {
    try {
      await authClient.changePreferedLang({
        language: lang,
      });
    } catch (error) {
      ErrorToast({
        message: t('errors.languagePreferenceNotSet'),
      });
    }
  }

  return (
    <Select
      value={lang}
      defaultValue={lang}
      onValueChange={(value) => {
        router.push(`${pathname}?${createQueryString('lang', value)}`);
        setLang(value);
        if (sendRequestOnChange) {
          sendOnChangeLangRequestAsync(value);
        }
      }}
      data-testid="language-select"
    >
      <SelectTrigger
        className="w-auto flex-1 justify-between gap-4 px-4 py-2"
        classNameArrow="min-w-4 min-h-4 fill-primary"
        data-testid="language-select-trigger"
      >
        <div className="inline-flex">
          <span
            className={twMerge(
              hideIconOnMobile ? 'hidden sm:inline-block' : 'inline-block',
            )}
          >
            <GlobeIcon
              className={twMerge(
                'h-6 w-6 fill-primary',
                hideIconOnMobile ? 'sm:mr-3' : 'mr-3',
              )}
            />
          </span>
          <SelectValue className="mr-2" data-testid="language-select-value" />
        </div>
      </SelectTrigger>
      <SelectContent data-testid="language-select-content">
        {languageOptions.map((option, index) => (
          <SelectItem
            key={option.value}
            value={option.value}
            className={twMerge(
              "my-2 data-[state='checked']:bg-primary data-[state='checked']:text-white",
              'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
              'ring-offset-background focus-visible:ring-offset-2',
            )}
            data-testid={`language-select-item-${index}`}
          >
            {t(option.label)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default LanguageSelect;
