import { useState, useCallback } from 'react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { Attachment, IntSEALObject } from '../_types/DataTypes';
import { getAttachmentBlob } from '../_services/IPFSService/IPFSService';
import {
  getRecipientsAsText,
  parseContentTypeHeader,
} from '../_utils/SEALutils';
import { useTranslation } from 'next-export-i18n';
import { IpfsClient } from '../_clients/ipfs';
import { generateDateForFileName } from '../_utils/dateUtils';

export interface LoadingHashes {
  [key: string]: boolean;
}

const useAttachments = (
  attachments: Attachment[] | undefined,
  ipfsClient: IpfsClient,
) => {
  const { t } = useTranslation();
  const [loadingHashes, setLoadingHashes] = useState<LoadingHashes>({});

  const downloadSingleAttachment = useCallback(
    async (attachment: Attachment) => {
      if (!attachments) return;
      if (loadingHashes[attachment.cid]) return;

      setLoadingHashes({
        ...loadingHashes,
        [attachment.cid]: true,
      });

      try {
        console.log('before getAttachmentBlob');

        //TODO: get tail
        let blob = await getAttachmentBlob(attachment, ipfsClient);

        console.log('after getAttachmentBlob', blob);

        let { name } = parseContentTypeHeader(
          attachment.headers['Content-Type'][0],
        );

        const attachmentFileName =
          name !== '' ? name : t('common.noAttachmentFileName');
        console.log('after parseContentTypeHeader', attachmentFileName);

        if (blob) {
          console.log('SAVING blob');

          saveAs(blob, attachmentFileName);
        } else {
          // TODO: sonner error

          console.log('inside the ele (no blob)');
        }
      } catch (e) {}

      console.log('setting new state');
      console.log(loadingHashes);

      setLoadingHashes({ ...loadingHashes });
    },
    [attachments, loadingHashes, t, ipfsClient],
  );

  const downloadAllAttachments = useCallback(
    async (
      content: Attachment[],
      ipfsClient: IpfsClient,
      qrCodeData?: IntSEALObject,
    ) => {
      if (!attachments) return;

      const zip = new JSZip();

      const img = zip.folder('attachment-files');

      const date = new Date();
      let dateForZIPName = generateDateForFileName(date);
      let fileArray = [];
      let generatedZipName = 'hin-mail-attachments-' + dateForZIPName;
      try {
        for await (let item of content) {
          if (loadingHashes[item.cid]) return;

          setLoadingHashes({
            ...loadingHashes,
            [item.cid]: true,
          });

          let contentBlob = getAttachmentBlob(item, ipfsClient);
          let { name } = parseContentTypeHeader(
            item.headers['Content-Type'][0],
          );

          const attachmentFileName =
            name !== '' ? name : t('common.noAttachmentFileName');
          fileArray.push({
            contentBlob: await contentBlob,
            name: attachmentFileName,
          });

          setLoadingHashes((prev) => {
            const updated = { ...prev };
            delete updated[item.cid];
            return updated;
          });
        }
      } catch (e) {}

      if (fileArray.length > 0 && img) {
        for await (let zipItem of fileArray) {
          img.file(zipItem.name, zipItem.contentBlob as any);
        }

        if (qrCodeData && qrCodeData.sender && qrCodeData.recipients) {
          const recipientsText = getRecipientsAsText(qrCodeData.recipients.to);

          const sender = qrCodeData.sender?.name
            ? `${qrCodeData.sender.name} ${qrCodeData.sender.email}`
            : `${qrCodeData.sender.email}`;

          const textContent = `Sender: ${sender}\nRecipients: ${recipientsText}\nSubject: ${qrCodeData.subject || 'No Subject'}\n${qrCodeData?.plain?.content || 'No message content'}`;

          img.file('Message.txt', textContent);
        }

        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, `${generatedZipName}.zip`);
        });
      }

      setLoadingHashes({ ...loadingHashes });
    },
    [attachments, loadingHashes, t],
  );

  return {
    loadingHashes,
    downloadSingleAttachment,
    downloadAllAttachments,
  };
};

export default useAttachments;
