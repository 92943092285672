import FileDisplay from '@/app/_comps/FileDisplay/FileDisplay';
import ReplyButton from '@/app/_comps/ReplyButton/ReplyButton';
import {
  AttachmentState,
  GenericResponse,
  ReplyMailData,
} from '@/app/_types/DataTypes';
import {
  removeAllFiles,
  resizeTextAreaToContent,
  textAreaChange,
} from '@/app/_utils/formUtils';
import { DragEvent, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AttachmentIcon,
  Button,
  Card,
  DeleteIcon,
  Headline,
  ReplyIcon,
  Text,
} from '@vereign/ui';
import useFilesUpload from '@/app/_hooks/useFilesUpload';
import { useTranslation } from 'next-export-i18n';
import SendMeCopyCheckbox from './SendMeCopyCheckbox';
import { normalizeUserEmail } from '@/app/_utils/stringUtils';
import { Config } from '@/app/_config/schema';
import useQRStore from '@/app/_stores/QRStore';
import useUserStore from '@/app/_stores/UserStore';
import { AuthClient } from '@/app/_clients/auth';
import { mimeWordsDecode } from 'emailjs-mime-codec';
import ErrorToast from '@/app/_comps/Toast/ErrorToast/ErrorToast';
import SuccessToast from '@/app/_comps/Toast/SuccessToast/SuccessToast';

//TODO: so much state is that really needed ??
const Reply = ({ config }: { config: Config }) => {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const filesRef = useRef<HTMLInputElement>(null);

  const qrCodeData = useQRStore((state) => state.QR);

  const [sendMeCopyChecked, setSendMeCopyChecked] = useState<boolean>(false);

  const [fileValidations, setFileValidations] = useState<GenericResponse[]>([]);

  const [discardingMail, setDiscardingMail] = useState<boolean>(false);

  const [replyLoading, setReplyLoading] = useState<boolean>(false);

  const [dragOver, setDragOver] = useState<boolean>(false);

  const [mailData, setMailData] = useState<ReplyMailData>({
    body: '',
    sender: '',
    attachments: [],
  });

  const userEmail = useUserStore((state) => state.authenticatedEmail);

  const setReplyOpen = useUserStore((state) => state.setReplyOpen);
  const authClient = useMemo(
    () => new AuthClient(config.auth.baseUrl),
    [config.auth.baseUrl],
  );

  const [fileUploadState, handleFileDrop, handleFileInput, setFileUploadState] =
    useFilesUpload(authClient);

  const discardDraftDisabled = !mailData.body && !mailData.attachments.length;

  async function discardMailData() {
    setDiscardingMail(true);

    if (!qrCodeData || !mailData.attachments.length) {
      setMailData((prev) => ({
        ...prev,
        body: '',
      }));
      setDiscardingMail(false);
      return;
    }

    await removeAllFiles({
      files: mailData.attachments,
      onRequest: async (file) => {
        try {
          const c = await authClient.deleteFile(
            file.name,
            qrCodeData.messageId!,
            qrCodeData.sealHash,
          );
          return c;
        } catch (error) {
          //TODO: do something with the error
          console.log(error);
          return;
        }
      },
      onEachResolve: (data) => {
        return setMailData((prev) => {
          let newAttachments = prev.attachments.filter(
            (f) => f.name !== data.filename,
          );
          return {
            ...prev,
            attachments: newAttachments,
          };
        });
      },
      onEnd: handleRemoveFileEnd,
    });

    setMailData((prev) => ({
      ...prev,
      body: '',
    }));

    setDiscardingMail(false);
  }

  const handleRemoveFileEnd = () => {
    if (
      filesRef &&
      filesRef.current &&
      filesRef.current.files &&
      filesRef.current.files.length > 0
    ) {
      filesRef.current.value = '';
      filesRef.current.files = null;
    }
  };

  const handleDrag = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragOver(true);
    } else if (e.type === 'dragleave') {
      setDragOver(false);
    }
  };

  const handleReplySubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    // if sendMeCopyChecked=true loop through all normalized recipients
    // and send a copy to the first matching user
    const allRecipients = [
      ...(qrCodeData?.recipients.to || []),
      ...(qrCodeData?.recipients.cc || []),
    ];
    const receiver = allRecipients.find((recipient) => {
      const normalizedRecipientEmail = normalizeUserEmail(recipient.email);
      return normalizedRecipientEmail === userEmail;
    });
    const sendCopyTo = receiver?.email;

    if (!mailData || !mailData.body) {
      ErrorToast({ message: t('toasts.emptyReplyBody') });
      return;
    }
    //TODO: do I need to decode this ???
    const decodedSubject = mimeWordsDecode(qrCodeData?.subject);

    const data = {
      body: mailData.body,
      messageHash: qrCodeData?.sealHash,
      messageId: qrCodeData?.messageId,
      recipient: qrCodeData?.replyTo
        ? qrCodeData?.replyTo
        : qrCodeData?.sender.email,
      subject: `${t('common.re')} ${decodedSubject}`,
      bcc: sendMeCopyChecked ? sendCopyTo : undefined,
    };

    try {
      setReplyLoading(true);
      await authClient.reply(data);
      setMailData({
        body: '',
        sender: '',
        attachments: [],
      });
      SuccessToast({ message: 'Reply sent successfully' });
      setReplyOpen(false);
    } catch (error) {
      console.log(error);
      ErrorToast({ message: 'Error sending reply message' });
    } finally {
      setReplyLoading(false);
    }
  };

  const handleFileRemove = async (fileIndex: number) => {
    const fileForRemoval = mailData.attachments[fileIndex];

    try {
      await authClient.deleteFile(
        fileForRemoval.name,
        qrCodeData?.messageId!,
        qrCodeData?.sealHash!,
      );

      setMailData((prev) => ({
        ...prev,
        attachments: prev.attachments.filter(
          (_: AttachmentState, i: number) => {
            return i !== fileIndex;
          },
        ),
      }));

      //TODO: why do I need this at all ???
      if (
        filesRef &&
        filesRef.current &&
        filesRef.current.files &&
        filesRef.current.files.length > 0
      ) {
        filesRef.current.value = '';
        filesRef.current.files = null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUploadFail = (fileName: string) => {
    setFileUploadState((prev) => {
      return prev.filter((f) => f.fileName !== fileName);
    });

    setMailData((prev) => ({
      ...prev,
      attachments: prev.attachments.filter((f: AttachmentState) => {
        return f.name !== fileName;
      }),
    }));

    handleRemoveFileEnd();
  };

  useEffect(() => {
    (async () => {
      if (qrCodeData && qrCodeData.messageId && qrCodeData.sealHash) {
        try {
          const res = await authClient.getMessageFiles(
            qrCodeData.messageId,
            qrCodeData.sealHash,
          );

          setMailData((prev) => {
            return {
              ...prev,
              attachments: !res.files
                ? []
                : res.files?.map((file: any) => ({
                    name: file.name,
                    size: file.size,
                  })),
            };
          });
        } catch (error) {
          //TODO: do something with the error
          console.log(error);
        }
      }
    })();
  }, [authClient, qrCodeData]);

  return (
    <Card
      data-testid="reply-box"
      className="relative p-5 lg:p-8 print:hidden"
      onDragEnter={handleDrag}
    >
      <div
        id="drop-zone"
        data-testid="drop-file-zone"
        className={twMerge(
          'absolute -bottom-0.5 -left-0.5 -right-0.5 -top-0.5 z-50 items-center justify-center',
          'rounded-2xl bg-black opacity-60',
          dragOver ? 'flex' : 'hidden',
        )}
        onDrop={
          /* istanbul ignore next */
          (ev) =>
            handleFileDrop(
              ev,
              setDragOver,
              mailData,
              setMailData,
              qrCodeData,
              t,
              setFileValidations,
            )
        }
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
      >
        <Text
          data-testid="drop-file-text"
          className="z-[-1] text-4xl tracking-wide text-white"
        >
          {t('pages.emailPage.dropFileText')}
        </Text>
      </div>
      <div
        data-testid="reply-to-header"
        className="flex flex-row items-center gap-5"
      >
        <ReplyIcon
          data-testid="reply-icon"
          title="reply icon header"
          className="h-6 w-6"
        />
        <div className="flex flex-col items-center lg:flex-row lg:gap-5">
          <div data-testid="reply-to-name">
            <p className="text-sm font-bold">{t('pages.emailPage.replyTo')}</p>{' '}
            <p className="flex flex-row gap-3 text-xl font-bold">
              {qrCodeData?.sender.name && (
                <span className="inline-flex">{qrCodeData?.sender.name}</span>
              )}
              <span className="inline-flex self-end text-sm font-thin">
                {qrCodeData?.replyTo
                  ? qrCodeData?.replyTo
                  : qrCodeData?.sender.email}
              </span>
            </p>
          </div>
          <div>
            <p
              data-testid="reply-to-email"
              className="leading-3 text-primary-foreground"
            ></p>
          </div>
        </div>
      </div>
      <form data-testid="reply-form" onSubmit={handleReplySubmit}>
        <textarea
          autoFocus
          id="replyBox"
          data-testid="reply-textarea"
          className={twMerge(
            'my-4 w-full resize-none rounded-lg border-0 bg-card p-4',
            'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
            'ring-offset-background focus-visible:ring-offset-2',
            'placeholder:font-bold placeholder:text-secondary-foreground',
          )}
          ref={textAreaRef}
          onChange={(ev) => textAreaChange(ev, setMailData)}
          value={mailData.body ? mailData.body : ''}
          placeholder={t('common.buttons.write')}
          onInput={
            /* istanbul ignore next */
            () => resizeTextAreaToContent(textAreaRef)
          }
          onBlur={
            /* istanbul ignore next */
            () => resizeTextAreaToContent(textAreaRef)
          }
          maxLength={config.general.maxReplyTextLen}
          rows={5}
        ></textarea>

        {discardingMail && (
          <div
            data-testid="discarding-mail-loader"
            className={twMerge(
              'absolute -bottom-0.5 -left-0.5 -right-0.5 -top-0.5 z-50 flex flex-col items-center',
              'justify-center rounded-2xl bg-black opacity-60',
            )}
          >
            <Text
              data-testid="discarding-mail-text"
              className="z-[-1] text-4xl tracking-wide text-white"
            >
              Discarding...
            </Text>
          </div>
        )}
        <FileDisplay
          files={mailData.attachments}
          fileUploadState={fileUploadState}
          onFileRemove={handleFileRemove}
          onFailUpload={handleFileUploadFail}
        />
        <div className="hidden flex-1 flex-row items-center gap-5 lg:flex">
          <SendMeCopyCheckbox
            id="send-me-a-copy-web"
            checked={sendMeCopyChecked}
            onCheckedChange={setSendMeCopyChecked}
          />
        </div>
        <div className="my-5 flex flex-1 flex-col-reverse gap-2.5 lg:flex-initial lg:flex-row">
          <ReplyButton
            data-testid="send-reply-button"
            type="submit"
            disabled={
              replyLoading ||
              (!mailData.body && mailData.attachments.length === 0)
            }
          >
            {t('common.buttons.reply')}
          </ReplyButton>

          <div className="flex flex-1 flex-row items-center gap-5 lg:hidden">
            <SendMeCopyCheckbox
              id="send-me-a-copy-mobile"
              checked={sendMeCopyChecked}
              onCheckedChange={setSendMeCopyChecked}
            />
          </div>

          <div className="flex flex-1 flex-row justify-between">
            <input
              ref={filesRef}
              onChange={(ev) => {
                handleFileInput(
                  ev,
                  setMailData,
                  mailData,
                  setFileValidations,
                  qrCodeData,
                  t,
                );
              }}
              className="absolute z-[-1] hidden h-0 w-0"
              id="files-input"
              data-testid="files-input"
              type="file"
              name="file-upload"
              multiple
            />
            <Button
              data-testid="attach-files-button"
              variant="ghost"
              type="button"
              className="flex flex-row px-0 text-primary underline hover:bg-card hover:text-primary lg:px-4"
              onClick={() => {
                filesRef?.current?.click();
              }}
            >
              <AttachmentIcon className="h-5 w-5 min-w-5 fill-primary" />
              <Text className="font-bold text-primary">
                {t('common.buttons.attach')}
              </Text>
            </Button>

            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  data-testid="discard-draft-button"
                  variant="ghost"
                  type="button"
                  disabled={discardDraftDisabled}
                  className={twMerge(
                    'flex flex-row px-0 text-primary underline hover:bg-card hover:text-primary lg:px-4',
                    discardDraftDisabled
                      ? '!pointer-events-auto cursor-not-allowed'
                      : '',
                  )}
                >
                  <DeleteIcon className="h-5 w-5 min-w-5 fill-primary" />
                  <Text className="font-bold text-primary">
                    {t('common.buttons.discard')}
                  </Text>
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent
                data-testid="discard-draft-alert-dialog"
                className="max-w-md"
              >
                <AlertDialogHeader>
                  <AlertDialogTitle
                    data-testid="discard-draft-dialog-title"
                    className="text-center"
                  >
                    {t('pages.emailPage.modal.title')}
                  </AlertDialogTitle>
                  <AlertDialogDescription
                    data-testid="discard-draft-dialog-description"
                    className="text-center text-primary-foreground"
                  >
                    {t('pages.emailPage.modal.description')}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="flex-col">
                  <AlertDialogAction
                    data-testid="discard-draft-dialog-confirm"
                    className="flex-1 uppercase"
                    onClick={() => {
                      discardMailData();
                    }}
                  >
                    {t('common.buttons.yes')}
                  </AlertDialogAction>
                  <AlertDialogCancel
                    data-testid="discard-draft-dialog-decline"
                    className="flex-1 uppercase"
                  >
                    {t('common.buttons.cancel')}
                  </AlertDialogCancel>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default Reply;
