import * as fflate from 'fflate';
import { ensureUint8Array } from './stringUtils';

export const debounceFn = (fn: Function, delayMs: number) => {
  let timeoutID: any;
  return function (...args: any) {
    if (timeoutID) clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fn(...args);
    }, delayMs);
  };
};

export const sumOfArray = ({
  arr,
  initialValue,
  arrayObjectKey,
}: {
  arr: any[];
  initialValue: number;
  arrayObjectKey?: string;
}) => {
  let sum: number;
  if (arrayObjectKey) {
    sum = arr.reduce(
      (accumulator, currentValue: any) =>
        accumulator + currentValue[arrayObjectKey],
      initialValue,
    );

    return sum;
  }

  sum = arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue,
  );

  return sum;
};

export const compressData = (binary: string | Uint8Array): Uint8Array => {
  return fflate.zipSync({ content: ensureUint8Array(binary) });
};

export const decompressData = (
  binary: Uint8Array | ArrayBuffer,
): fflate.Unzipped => {
  return fflate.unzipSync(ensureUint8Array(binary));
};

export function copyToClipboard(str: string): void {
  navigator.clipboard.writeText(str);
}
