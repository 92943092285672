import {
  Attachment,
  ExtSEALObject,
  IPFSDataRequest,
  IntSEALObject,
} from '@/app/_types/DataTypes';
import { checkIfHtmlHasContent, toArrayBuffer } from '@/app/_utils/stringUtils';
import { decryptAESGCM, sha256Digest } from '@/app/_utils/cryptoUtils';
import {
  parseContentTypePlainHeader,
  parseMessageIdHeader,
  parseMimeParticipants,
} from '@/app/_utils/SEALutils';
import { decompressData } from '@/app/_utils/functionUtils';
import { ParsedPath } from '../../_types/DataTypes';
import { IpfsClient } from '@/app/_clients/ipfs';
import {
  base64Decode,
  mimeWordsDecode,
  quotedPrintableDecode,
} from 'emailjs-mime-codec';
import { getAttachmentBase64 } from '../IPFSService/IPFSService';
export const AssembleIPFSData = async ({
  key,
  iv,
  head,
  tail,
  encoding,
}: IPFSDataRequest) => {
  const objectToDecrypt = concatHeadAndTail({ head, tail });

  return decryptAndDecompressData({
    objectToDecrypt,
    key,
    iv,
    encoding,
  });
};

export const mapToInternalDataObject = async (
  extData: ExtSEALObject,
  ipfsClient: IpfsClient,
): Promise<IntSEALObject> => {
  let plain:
    | Pick<NonNullable<IntSEALObject['plain']>, 'content' | 'len' | 'type'>
    | undefined;

  let html:
    | Pick<NonNullable<IntSEALObject['html']>, 'content' | 'len' | 'type'>
    | undefined;

  if (extData.html) {
    let htmlContent = await AssembleIPFSData({
      key: Buffer.from(extData.html?.key, 'hex'),
      iv: Buffer.from(extData.html?.nonce, 'hex'),
      head: Buffer.from(extData.html?.head, 'hex'),
      tail: Buffer.from(extData.html.tail),
      encoding: getEncoding(extData.html.headers?.['Content-Type']?.[0]),
    });

    const containsContent = checkIfHtmlHasContent(htmlContent);

    html = await formatHtml({
      unformattedHtml: containsContent ? htmlContent : undefined,
      type: extData.html?.type!,
      len: extData.html?.len!,
      attachments: extData.attachments,
      ipfsClient,
    });
  }

  if (extData.plain) {
    let plainTextContent = await AssembleIPFSData({
      key: Buffer.from(extData.plain?.key, 'hex'),
      iv: Buffer.from(extData.plain?.nonce, 'hex'),
      head: Buffer.from(extData.plain?.head, 'hex'),
      tail: Buffer.from(extData.plain.tail),
      encoding: getEncoding(extData.plain.headers?.['Content-Type']?.[0]),
    });

    plain = formatPlain({
      content: plainTextContent,
      type: extData.plain?.type!,
      len: extData.plain?.len!,
      contentTransferEncoding:
        extData.plain?.headers?.['Content-Transfer-Encoding']?.[0] ||
        extData.headers?.['Content-Transfer-Encoding'] ||
        'base64',
    });
  }

  const internalDataObject: IntSEALObject = {
    sender: parseMimeParticipants(extData.headers.From)[0],
    messageId: parseMessageIdHeader(extData.headers['Message-Id'][0]),
    sealHash: extData.sealHash,
    subject: mimeWordsDecode(extData.headers?.Subject?.[0] || ''),
    recipients: {
      to: parseMimeParticipants(extData.headers.To),
      cc: extData.headers.Cc ? parseMimeParticipants(extData.headers.Cc) : [],
    },
    dateOfSending: extData.headers.Date,
    dateOfSealing: Number(extData.dateOfSealing),
    plain,
    html,
    attachments: extData.attachments,
    replyTo: parseMimeParticipants(extData.headers['Reply-To'])[0]?.email
      ? parseMimeParticipants(extData.headers['Reply-To'])[0]?.email
      : undefined,
  };

  return internalDataObject;
};

export const assembleURL = async (
  parsedPath: ParsedPath,
  sealTail: Uint8Array | '',
  sealHash: string,
  ipfsClient: IpfsClient,
) => {
  let SEALData = await AssembleIPFSData({
    key: parsedPath.key,
    iv: parsedPath.iv,
    head: parsedPath.head,
    tail: sealTail,
  });
  const jsonData = JSON.parse(SEALData);

  if (jsonData.html && jsonData.html.cid) {
    const htmlTail = await ipfsClient.get(jsonData.html.cid);
    jsonData.html.tail = htmlTail;
  }

  if (jsonData.plain && jsonData.plain.cid) {
    const plainTail = await ipfsClient.get(jsonData.plain.cid);
    jsonData.plain.tail = plainTail;
  }

  const mappedData = await mapToInternalDataObject(
    {
      ...jsonData,
      sealHash,
    },
    ipfsClient,
  );

  return mappedData;
};

export const assembleHashedEncryptedIPFSData = async ({
  head,
  tail,
}: {
  head: Uint8Array;
  tail: Uint8Array;
}): Promise<ArrayBuffer> => {
  let objectToDecrypt = concatHeadAndTail({ head, tail });

  let arrayBufferObject = toArrayBuffer(objectToDecrypt);

  let digestedObject = await sha256Digest(arrayBufferObject);

  return digestedObject;
};

const decryptAndDecompressData = async ({
  objectToDecrypt,
  key,
  iv,
  encoding,
}: {
  objectToDecrypt: Uint8Array;
  key: Uint8Array;
  iv: Uint8Array;
  encoding: string | undefined;
}) => {
  const decrypted = await decryptAESGCM({
    data: objectToDecrypt,
    key: key,
    iv: iv,
  });

  const decompressed = decompressData(decrypted);
  const contentEncoded = Buffer.from(decompressed.content);

  return uint8ArrayToSpecificEncoding({ data: contentEncoded, encoding });
};

function uint8ArrayToSpecificEncoding({
  encoding = 'utf-8',
  data,
}: {
  encoding: string | undefined;
  data: Uint8Array;
}) {
  const decoder = new TextDecoder(encoding);
  return decoder.decode(data);
}

function concatHeadAndTail({
  head,
  tail = '',
}: {
  head: Uint8Array;
  tail: Uint8Array | '';
}) {
  return Buffer.concat([Buffer.from(head), Buffer.from(tail)]);
}

function getEncoding(headers: string | null) {
  if (!headers) return 'utf-8';
  return parseContentTypePlainHeader(headers).charset;
}

const formatPlain = ({
  content,
  type,
  len,
  contentTransferEncoding,
}: {
  content: string;
  type: string;
  len: number;
  contentTransferEncoding?: string;
}) => {
  if (!content) return undefined;

  if (contentTransferEncoding == 'base64') {
    return {
      content: base64Decode(content),
      len,
      type,
    };
  }

  return {
    content: quotedPrintableDecode(content),
    len,
    type,
  };
};

const formatHtml = async ({
  unformattedHtml,
  type,
  len,
  attachments,
  ipfsClient,
}: {
  unformattedHtml: string | undefined;
  type: string;
  len: number;
  attachments: Attachment[] | undefined;
  ipfsClient: IpfsClient;
}) => {
  if (!unformattedHtml) return undefined;
  let content = quotedPrintableDecode(unformattedHtml);

  if (attachments) {
    for (const attachment of attachments) {
      if (!attachment.headers['Content-Id']) {
        continue;
      }

      const attachmentMailCID = attachment.headers['Content-Id'][0]
        .replace('<', '')
        .replace('>', '');

      const attachmentBase64 = await getAttachmentBase64(
        attachment,
        ipfsClient,
      );

      content = content.replaceAll(
        `cid:${attachmentMailCID}`,
        `${attachmentBase64}`,
      );
    }
  }

  return {
    content,
    len: len,
    type: type,
  };
};
