import { Button, DownloadIcon, Text } from '@vereign/ui';
import Attachments from './Attachments/Attachments';
import EmailBody from './EmailBody/EmailBody';
import EmailMeta from './EmailMeta/EmailMeta';
import Reply from './Reply/Reply';
import { useTranslation } from 'next-export-i18n';
import { Config } from '@/app/_config/schema';
import useUserStore from '@/app/_stores/UserStore';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { parseSEALUrl } from '@/app/_utils/SEALutils';
import { ParsedPath, SEALAudience } from '@/app/_types/DataTypes';
import { IpfsClient } from '@/app/_clients/ipfs';
import {
  assembleHashedEncryptedIPFSData,
  assembleURL,
} from '@/app/_services/SEALDataService/SEALDataService';
import { unicodeToHex } from '@/app/_utils/stringUtils';
import useQRStore from '@/app/_stores/QRStore';
import { AuthClient } from '@/app/_clients/auth';
import { withConfig } from '@/app/_hocs/withConfig';

function handlePrintPage() {
  window.print();
  return;
}

const EmailPage = ({ config }: { config: Config }) => {
  const { t } = useTranslation();
  const replyOpen = useUserStore((state) => state.replyOpen);
  const searchParams = useSearchParams();
  const q = searchParams.get('q');
  const setQR = useQRStore((state) => state.setQR);
  const QR = useQRStore((state) => state.QR);
  const router = useRouter();
  const setIsAuthenticated = useUserStore(
    (state) => state.setAuthenticatedEmail,
  );

  const ipfsClient = useMemo(
    () => new IpfsClient({ ipfsAddr: config.ipfs.baseUrl, timeoutMs: 10000 }),
    [config.ipfs.baseUrl],
  );

  const authClient = useMemo(
    () => new AuthClient(config.auth.baseUrl),
    [config.auth.baseUrl],
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!q) return;

    if (typeof QR !== 'undefined') {
      setIsLoading(false);
      return;
    }

    const processSealUrl = async () => {
      let parsedPath: ParsedPath;
      try {
        parsedPath = parseSEALUrl(q);
      } catch (e) {
        router.push('/invalid');
        return;
      }

      if (
        parsedPath &&
        (parsedPath.audience === SEALAudience.SINGLE ||
          parsedPath.audience === SEALAudience.MULTIPLE)
      ) {
        try {
          setIsLoading(true);
          const tail = await ipfsClient.get(parsedPath.CID);
          const messageHash = await assembleHashedEncryptedIPFSData({
            head: parsedPath.head,
            tail,
          });
          const sealHash = unicodeToHex(messageHash);

          const key = await authClient.keyDecrypt({
            messageHash: sealHash,
            sealKey: unicodeToHex(parsedPath.key),
          });

          const sealKey = Buffer.from(key.decryptedKey!, 'hex');
          parsedPath.key = sealKey;

          const mappedData = await assembleURL(
            parsedPath,
            tail,
            sealHash,
            ipfsClient,
          );

          setQR(mappedData);
          setIsLoading(false);
        } catch (error) {
          console.error('Error processing SEAL URL:', error);
          setIsLoading(false);
          //TODO: do we need to parse the error ??
          //Does general keyDecrypt means show auth screen?
          setIsAuthenticated(null);
        }
      }
    };

    processSealUrl();
  }, [q, ipfsClient, authClient, setQR, QR, router, setIsAuthenticated]);

  //TODO: this should be a sceleton
  // if (isLoading) return <div>test</div>;

  return (
    <div
      data-testid="email-page"
      className="flex w-full max-w-screen-2xl flex-1 flex-col gap-4 lg:flex-row"
    >
      <div className="flex flex-[2.5] flex-col gap-4">
        <EmailMeta config={config} />
        <EmailBody config={config} />
        {replyOpen && <Reply config={config} />}
        <Button
          data-testid="download-as-pdf-button"
          variant="ghost"
          type="button"
          className="flex h-auto w-fit flex-row self-center rounded-sm p-0 text-primary underline hover:bg-transparent hover:text-primary"
          onClick={handlePrintPage}
        >
          <Text className="text-primary">
            {t('common.buttons.downloadPdf')}
          </Text>
          <DownloadIcon className="w-4 fill-primary" />
        </Button>
      </div>

      <div className="flex min-w-80 flex-1 flex-col items-stretch gap-4">
        <Attachments config={config} />
      </div>
    </div>
  );
};

export default withConfig(EmailPage);
