import { Button, SettingsIcon, X } from '@vereign/ui';
import React from 'react';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { usePathname } from 'next/navigation';
import { SEALAudience } from '@/app/_types/DataTypes';
import useUserStore from '@/app/_stores/UserStore';
import useQRStore from '@/app/_stores/QRStore';

export default function Header() {
  const userEmail = useUserStore((state) => state.authenticatedEmail);
  const audience = useQRStore((state) => state.audience);
  const setIsSettingsOpen = useUserStore((state) => state.setIsSettingsOpen);
  const isSettingsPageOpen = useUserStore((state) => state.isSettingsOpen);
  const pathname = usePathname();

  return (
    <div
      data-testid="header"
      className="mb-4 flex w-full max-w-screen-2xl flex-row items-center justify-between gap-4"
    >
      <div className="flex flex-1 lg:flex-[2.5]">
        <img
          data-testid="hin-logo-img"
          src="/graphics/hin-logo.png"
          alt="HIN Logo"
          className="w-32 lg:w-40"
        />
      </div>
      <div className="flex min-w-48 flex-1 animate-flip-in-from-right justify-end gap-4 lg:min-w-80">
        {userEmail && audience !== SEALAudience.PUBLIC && pathname === '/' ? (
          !isSettingsPageOpen ? (
            <Button
              data-testid="settings-button"
              variant="round"
              size="icon"
              className="min-h-12 min-w-12 bg-primary"
              onClick={() => {
                setIsSettingsOpen(true);
              }}
            >
              <SettingsIcon className="h-7 w-7" />
            </Button>
          ) : (
            <Button
              data-testid="close-settings-button"
              variant="round"
              size="icon"
              className="min-h-12 min-w-12 bg-primary"
              onClick={() => {
                setIsSettingsOpen(false);
              }}
            >
              <X className="h-4 w-4" />
            </Button>
          )
        ) : (
          <>
            <div className="w-10"></div>
            <LanguageSelect hideIconOnMobile />
          </>
        )}
      </div>
    </div>
  );
}
