import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Text,
} from '@vereign/ui';
import Recipient from './Recipient';
import { twMerge } from 'tailwind-merge';
import { Participant } from '@/app/_types/DataTypes';
import { useTranslation } from 'next-export-i18n';

const CCText = ({ t }: { t: any }) => (
  <Text
    data-testid="recipients-cc-text"
    className="mt-2 inline-block text-sm font-bold"
  >
    {t('common.cc')}
  </Text>
);

function RecipientsBox({
  recipients,
}: {
  recipients: { to: Participant[]; cc: Participant[] };
}) {
  const { t } = useTranslation();
  const recipientsToCount = recipients.to.length;
  const recipientsCcCount = recipients.cc.length;
  const allRecipients = [...recipients.to, ...recipients.cc];
  const allRecipientsCount = allRecipients.length;

  const VISIBLE_RECIPIENTS_COUNT = 3;
  const HIDDEN_RECIPIENTS_COUNT = allRecipientsCount - VISIBLE_RECIPIENTS_COUNT;
  let currentRecipientIndex = -1;

  return (
    <Accordion
      className="w-full"
      data-testid="recipients-container"
      type="single"
      collapsible
    >
      <AccordionItem
        value="item-1"
        className="flex flex-1 flex-col items-stretch border-b-0"
        data-testid="visible-recipients"
      >
        {allRecipients.slice(0, VISIBLE_RECIPIENTS_COUNT).map((recipient) => {
          currentRecipientIndex++;
          return (
            <React.Fragment key={recipient.email}>
              {currentRecipientIndex === 0 ? (
                <Text
                  data-testid="recipients-to-text"
                  className="text-sm font-bold"
                >
                  {t('common.to')}
                </Text>
              ) : null}
              {recipientsCcCount &&
              currentRecipientIndex === recipientsToCount ? (
                <CCText t={t} />
              ) : null}
              <Recipient
                name={recipient.name}
                email={recipient.email}
                index={currentRecipientIndex}
                skipBorder={
                  currentRecipientIndex === 0 ||
                  !!(
                    recipientsCcCount &&
                    currentRecipientIndex === recipientsToCount
                  )
                }
              />
            </React.Fragment>
          );
        })}
        {allRecipientsCount > VISIBLE_RECIPIENTS_COUNT && (
          <AccordionContent data-testid="expandable-recipients">
            {allRecipients.slice(VISIBLE_RECIPIENTS_COUNT).map((recipient) => {
              currentRecipientIndex++;
              return (
                <React.Fragment key={recipient.email}>
                  {recipientsCcCount &&
                  currentRecipientIndex === recipientsToCount ? (
                    <CCText t={t} />
                  ) : null}
                  <Recipient
                    name={recipient.name}
                    email={recipient.email}
                    index={currentRecipientIndex}
                    skipBorder={
                      !!(
                        recipientsCcCount &&
                        currentRecipientIndex === recipientsToCount
                      )
                    }
                  />
                </React.Fragment>
              );
            })}
          </AccordionContent>
        )}
        {allRecipientsCount > VISIBLE_RECIPIENTS_COUNT && (
          <AccordionTrigger
            data-testid="expand-recipients-button"
            className={twMerge(
              'flex justify-center gap-2 pb-0 underline [&[data-state=closed]>.open]:flex [&[data-state=open]>.open]:hidden',
              '[&[data-state=closed]>.close]:hidden [&[data-state=open]>.close]:flex',
            )}
          >
            <Text data-testid="expand-recipients-open-text" className="open">
              {t('common.links.more', { number: HIDDEN_RECIPIENTS_COUNT })}
            </Text>
            <Text data-testid="expand-recipients-close-text" className="close">
              {t('common.buttons.close')}
            </Text>
          </AccordionTrigger>
        )}
      </AccordionItem>
    </Accordion>
  );
}

export default RecipientsBox;
