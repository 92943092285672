import { SelectItem } from '@vereign/ui';
import React from 'react';
import { Country, getCountryCallingCode } from 'react-phone-number-input/input';
import { twMerge } from 'tailwind-merge';

interface CountrySelectItemProps {
  country: Country;
  Icon: React.FC<{ country: string }>;
}

const CountrySelectItem: React.FC<CountrySelectItemProps> = ({
  country,
  Icon,
}) => {
  return (
    <SelectItem
      value={country}
      className={twMerge(
        "my-2 pl-2 pr-2 data-[state='checked']:bg-primary data-[state='checked']:text-white",
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
        'ring-offset-background focus-visible:ring-offset-2',
      )}
      data-testid={`country-select-item-${country}`}
    >
      <div className="align-center flex flex-row justify-center gap-2">
        <Icon country={country} />
        <span className="min-w-5 leading-none">{country}</span>
        <span className="leading-none">+{getCountryCallingCode(country)}</span>
      </div>
    </SelectItem>
  );
};

export default CountrySelectItem;
