import { ToastProps } from '../../../_types/ComponentProps';
import { toast } from '@vereign/ui';

export default function ErrorToast({ message }: ToastProps) {
  return toast.error(message, {
    className: '',
    description: '',
    // icon: <ErrorIconExample />,
  });
}
