import ReplyButton from '@/app/_comps/ReplyButton/ReplyButton';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Headline,
} from '@vereign/ui';
import { useTranslation } from 'next-export-i18n';
import { Config } from '@/app/_config/schema';
import useQRStore from '@/app/_stores/QRStore';
import useUserStore from '@/app/_stores/UserStore';

const EmailBody = ({ config }: { config: Config }) => {
  const { t } = useTranslation();
  const qrCodeData = useQRStore((state) => state.QR);

  const setReplyOpen = useUserStore((state) => state.setReplyOpen);
  const replyOpen = useUserStore((state) => state.replyOpen);

  const plain = qrCodeData?.plain?.content;
  const html = qrCodeData?.html?.content;
  const subject = qrCodeData?.subject;

  return (
    <Card data-testid="email-body-box" className="lg:p-2">
      <CardHeader>
        <div className="flex flex-row items-center justify-between gap-5 border-b-[3px] border-dashed pb-4">
          <Headline
            as="h1"
            id="skip-to-email-content"
            data-testid="email-subject"
            className="text-2xl font-bold"
          >
            {qrCodeData && subject ? subject : t('common.noSubject')}
          </Headline>
          {!replyOpen && (
            <div className="hidden lg:inline-flex print:hidden">
              <ReplyButton
                data-testid="reply-button-web"
                scrollToId="replyBox"
                onClick={() => setReplyOpen(true)}
              >
                {t('common.buttons.reply')}
              </ReplyButton>
            </div>
          )}
        </div>
      </CardHeader>
      <CardContent data-testid="email-body-container">
        {!!html && (
          <div
            id="email-body"
            data-testid="email-body-html"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
        {!html && !!plain && (
          <pre data-testid="email-body-plain-text">{plain}</pre>
        )}
        {!html && !plain && (
          <div data-testid="no-email-body" className="text-primary-foreground">
            {t('common.noBody')}
          </div>
        )}
      </CardContent>

      {!replyOpen && (
        <CardFooter className="inline-flex w-full lg:hidden print:hidden">
          <ReplyButton
            data-testid="reply-button-mobile"
            scrollToId="replyBox"
            size="full"
            onClick={() => setReplyOpen(true)}
          >
            {t('common.buttons.reply')}
          </ReplyButton>
        </CardFooter>
      )}
    </Card>
  );
};

export default EmailBody;
