import { generateInitials } from '../../_utils/stringUtils';
import { twMerge } from 'tailwind-merge';
import Spinner from '../Spinner/Spinner';
import { useTranslation } from 'next-export-i18n';

interface Props {
  name?: string;
  email?: string;
  isLoading: boolean;
  imgSrc?: string;
  className?: string;
}

const GeneratedAvatar = ({
  name,
  email,
  isLoading,
  imgSrc,
  className,
}: Props) => {
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <div
        data-testid="avatar-loader"
        className={twMerge(
          'align-center flex h-full w-full justify-center rounded-full bg-primary text-2xl font-semibold',
          className,
        )}
      >
        <Spinner color="white" />
      </div>
    );
  }

  if (imgSrc) {
    return (
      <img
        className={twMerge(
          'align-center flex h-full w-full justify-center rounded-full text-2xl font-semibold',
          className,
        )}
        alt={t('common.avatar-image')}
        data-testid="avatar-image"
        src={imgSrc}
      />
    );
  }

  return (
    <div
      className={twMerge(
        'align-center flex h-full w-full items-center justify-center rounded-full bg-primary text-2xl font-semibold text-white',
        className,
      )}
    >
      <span data-testid="avatar-fallback-initials">
        {generateInitials(email, name)}
      </span>
    </div>
  );
};

export default GeneratedAvatar;
