import { AuthApiException, AuthClient } from '@/app/_clients/auth';
import { useRef, useState } from 'react';
import useOtpFunctions from '@/app/_hooks/useOtpFunctions';
import { validatePhone } from '@/app/_utils/validations';
import ErrorToast from '@/app/_comps/Toast/ErrorToast/ErrorToast';
import SuccessToast from '@/app/_comps/Toast/SuccessToast/SuccessToast';
import { Config } from '@/app/_config/schema';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

const CHANGE_PHONE_STEPS = {
  NEW_PHONE: 0,
  OTP: 1,
  SUCCESS: 2,
};

const CHANGE_PHONE_STEPS_COUNT = 3;

interface UseChangePhoneProps {
  t: any;
  config: Config;
  code?: string;
  email?: string;
}

function useChangePhone({ t, config, code, email }: UseChangePhoneProps) {
  const [phone, setPhone] = useState('');
  const [currentStepIdx, setCurrentStepIdx] = useState(
    CHANGE_PHONE_STEPS.NEW_PHONE,
  );

  const firstSubmitButtonRef = useRef<HTMLButtonElement>(null);

  const authClient = new AuthClient(config.auth.baseUrl);

  const {
    handleOTPForm,
    onOtpChange,
    otpError,
    submitOtpStateDisabled,
    OTP_LENGTH,
  } = useOtpFunctions({
    onRequest: async (smsCode: string) => {
      const res = await authClient.verifyNewMobileSMSCode({
        smsCode,
      });

      return res;
    },
    onOtpChangeSuccess: () => {
      setCurrentStepIdx(CHANGE_PHONE_STEPS.SUCCESS);
    },
  });

  const handleNewPhoneSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const phoneValidationResult = validatePhone({
      phone,
    });
    if (phoneValidationResult) {
      ErrorToast({
        message: t(phoneValidationResult),
      });
      return;
    }

    const form = e.target as HTMLFormElement;
    const phoneInput = form.elements.namedItem(
      'phone-input',
    ) as HTMLInputElement;

    try {
      const data = await authClient.verifyMobile({
        newMobile: phone,
        code,
        email,
      });

      SuccessToast({
        message: t('common.toasts.codeSent'),
      });
      setCurrentStepIdx(CHANGE_PHONE_STEPS.OTP);
    } catch (err) {
      console.error('Error:', err);

      if (AuthApiException.isAuthApiException(err)) {
        if (err.errorDto.errorCode === 'new_mobile_conflict') {
          ErrorToast({
            message: t('errors.mobileConflict'),
          });
          return;
        }
      }
      ErrorToast({
        message: t('errors.codeNotSent'),
      });
    }
  };

  return {
    handleNewPhoneSubmit,
    phone,
    setPhone,
    firstSubmitButtonRef,
    setCurrentStepIdx,
    handleOTPForm,
    formattedPhone: formatPhoneNumberIntl(phone),
    onOtpChange,
    OTP_LENGTH,
    otpError,
    submitOtpStateDisabled,
    currentStepIdx,
    CHANGE_PHONE_STEPS,
    CHANGE_PHONE_STEPS_COUNT,
  };
}

export default useChangePhone;
