import { Attachment } from '@/app/_types/DataTypes';
import { AssembleIPFSData } from '../SEALDataService/SEALDataService';
import { parseContentTypeHeader } from '@/app/_utils/SEALutils';
import { IpfsClient } from '@/app/_clients/ipfs';

//TODO: auth client should not be passed here
//TODO: this is called in a foreach loop !!!!
export const getAttachmentBase64 = async (
  attachment: Attachment | null,
  ipfsClient: IpfsClient,
): Promise<string | undefined> => {
  if (!attachment) return;

  const attachmentTail = await ipfsClient.get(attachment.cid);

  const fileContent = await AssembleIPFSData({
    tail: attachmentTail,
    iv: Buffer.from(attachment.nonce, 'hex'),
    head: Buffer.from(attachment.head, 'hex'),
    key: Buffer.from(attachment.key, 'hex'),
    ipfsVersion: 'v2',
  });

  const { type } = parseContentTypeHeader(
    attachment.headers['Content-Type'][0],
  );

  const b64 = `data:${type};base64,` + fileContent;

  return b64;
};

export const getAttachmentBlob = async (
  attachment: Attachment | null,
  ipfsClient: IpfsClient,
): Promise<any> => {
  if (!attachment) return;

  const b64 = (await getAttachmentBase64(attachment, ipfsClient)) as string;

  return await fetch(b64).then((res) => res.blob());
};
