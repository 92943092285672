import axios from 'axios';
import { Config } from '../_config/schema';

export const GENERIC_PERSON = '/graphics/generic-person.svg';

const getDomainFromEmail = (email: string): string =>
  email.substring(email.indexOf('@') + 1, email.length);

const getUrlIfAvailable = async (url: string): Promise<string> => {
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return url;
    }
    throw new Error(`Failed to fetch from ${url}`);
  } catch (error) {
    throw new Error(
      `Error fetching from ${url}: ${error instanceof Error ? error.message : String(error)}`,
    );
  }
};

const dnsLookUp = async (url: string) => {
  try {
    const response = await axios.get(url);
    if (response.status === 200 && response.data.Status === 0) {
      if (response.data.Answer[0].name.endsWith('.')) {
        return response.data.Answer[0].name.slice(0, -1);
      }
    }
    throw new Error(`Failed to fetch from ${url}`);
  } catch (error) {
    throw new Error(
      `Error fetching from ${url}: ${error instanceof Error ? error.message : String(error)}`,
    );
  }
};

export const getCompanyAvatar = async (
  hash: string,
  config: Config,
  email?: string,
): Promise<string> => {
  if (!email) {
    throw new Error('Email is required for company avatar');
  }

  const companyDomain = getDomainFromEmail(email);
  const dnsLookupUrl = `${config.avatar.dnsLookupPath}${companyDomain}`;
  try {
    const resolvedDomain = await dnsLookUp(dnsLookupUrl);
    return await getUrlIfAvailable(`https://${resolvedDomain}/${hash}`);
  } catch (error) {
    throw new Error(
      `Error getting avatar from ${companyDomain}: ${error instanceof Error ? error.message : String(error)}`,
    );
  }
};

const getDefaultLibravatar = async (
  hash: string,
  config: Config,
): Promise<string> => {
  const defaultUrl = `${config.avatar.baseUrl}/${config.avatar.getPath}/${hash}`;
  return getUrlIfAvailable(defaultUrl);
};

const getGravatar = async (hash: string, config: Config): Promise<string> => {
  if (!config.general.defaultToGravatarBool) {
    throw new Error('Gravatar is not enabled');
  }

  const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=200&d=404`;
  return getUrlIfAvailable(gravatarUrl);
};

export const getAvatarSrc = async (
  hash: string,
  config: Config,
  email?: string,
  name?: string,
): Promise<string> => {
  const sources = [
    () => getCompanyAvatar(hash, config, email),
    () => getDefaultLibravatar(hash, config),
    () => getGravatar(hash, config),
  ];

  const promises = sources.map((source) => source());
  const results = await Promise.allSettled(promises);

  const fulfilledResult = results.find(
    (result) => result.status === 'fulfilled',
  );

  if (fulfilledResult) {
    return fulfilledResult.value;
  }

  if (name) {
    throw new Error('No avatar source found');
  }

  return GENERIC_PERSON;
};
