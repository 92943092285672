import React from 'react';
import { Button, Headline, Text } from '@vereign/ui';
import Countdown, { CountdownRendererFn } from 'react-countdown';

const MILLISECONDS = 1000;
const RETRY_INTERVAL = 59 * MILLISECONDS;

interface ResendSMSProps {
  handleResendSms: () => void;
  retryCount: number;
  t: any;
}

function ResendSMS({ handleResendSms, retryCount, t }: ResendSMSProps) {
  const countdownRenderer: CountdownRendererFn = ({ seconds, completed }) =>
    completed ? (
      <Button
        variant="ghost"
        type="button"
        className="flex h-auto flex-row rounded-sm p-0 text-primary underline hover:bg-card hover:text-primary"
        onClick={handleResendSms}
      >
        <Text className="text-primary">
          {t('pages.authPage.requestNewCodeTimeout')}
        </Text>
      </Button>
    ) : (
      <span
        className="cursor-not-allowed text-sm font-medium text-primary"
        data-testid="resend-sms-countdown"
      >
        {t('pages.authPage.requestNewCode', { seconds: seconds })}
      </span>
    );
  return (
    <Countdown
      date={Date.now() + RETRY_INTERVAL}
      key={retryCount}
      renderer={countdownRenderer}
    />
  );
}

export default ResendSMS;
