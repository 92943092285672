'use client';

import localFont from 'next/font/local';
import './globals.css';
import Footer from './_comps/Footer/Footer';
import { Toaster, TooltipProvider } from '@vereign/ui';
import { twMerge } from 'tailwind-merge';
import Header from './_comps/Header/Header';
import SkipLinks from './_comps/SkipLinks/SkipLinks';
import { AuthProvider } from './_providers/authProvider';
import { useConfig } from './_hooks/useConfig';

const font = localFont({
  preload: true,
  src: [
    {
      path: './_comps/assets/fonts/HelveticaNeue.otf',
      weight: '200',
      style: 'normal',
    },
    {
      path: './_comps/assets/fonts/HelveticaNeue-Medium.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './_comps/assets/fonts/HelveticaNeue-Bold.otf',
      weight: '700',
      style: 'normal',
    },
  ],
});

// export const metadata: Metadata = {
//   title: 'HIN Verified Browser Delivery',
//   description: 'HIN Verified Browser Delivery. Verification app for the SEAL-ing result of an email.',
// };

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { config } = useConfig();

  if (!config) {
    return (
      <html>
        <body></body>
      </html>
    );
  }

  return (
    <html>
      <body className={font.className}>
        <Toaster
          expand
          visibleToasts={3}
          position="top-center"
          closeButton={true}
          toastOptions={{
            classNames: {
              toast: 'group-[.toaster]:border-primary',
              closeButton: twMerge(
                'left-auto -right-4',
                'group-[.toaster]:border-primary group-[.toast]:hover:!bg-white group-[.toast]:hover:!text-primary',
                'group-[.toast]:!bg-primary group-[.toast]:!text-white',
              ),
            },
          }}
        />
        <AuthProvider>
          <TooltipProvider>
            <SkipLinks />
            <div className="flex min-h-screen flex-col">
              <main className="block w-full min-w-[22rem]">
                <div className="flex flex-col items-center p-5 lg:px-24">
                  <Header />
                  {children}
                </div>
              </main>
              <Footer />
            </div>
          </TooltipProvider>
        </AuthProvider>
      </body>
    </html>
  );
}
