'use client';

import { Button, Card, CardHeader, Headline, Text } from '@vereign/ui';
import LanguageSelect from '@/app/_comps/LanguageSelect/LanguageSelect';
import ChangePhone from './ChangePhoneView';
import useChangePhone from '@/app/_hooks/useChangePhone';
import { useTranslation } from 'next-export-i18n';
import { Config } from '@/app/_config/schema';
import { withConfig } from '@/app/_hocs/withConfig';
import useUserStore from '@/app/_stores/UserStore';
import { AuthClient } from '@/app/_clients/auth';
import DoYouNeedHelpBox from '@/app/_comps/DoYouNeedHelpBox/DoYouNeedHelpBox';

const handleLogout = async ({
  client,
  onSuccess,
  onFail,
}: {
  client: AuthClient;
  onSuccess: () => void;
  onFail: (err: any) => void;
}) => {
  try {
    await client.logout();
    onSuccess();
  } catch (err) {
    onFail(err);
  }
};

function SettingsPage({ config }: { config: Config }) {
  const { t } = useTranslation();
  const authClient = new AuthClient(config.auth.baseUrl);
  const setUserEmail = useUserStore((state) => state.setAuthenticatedEmail);
  const userEmail = useUserStore((state) => state.authenticatedEmail);
  const setIsSettingsOpen = useUserStore((state) => state.setIsSettingsOpen);
  const changePhoneProps = useChangePhone({ t, config });

  return (
    <>
      <div className="w-full max-w-lg">
        <div className="flex flex-1 flex-col justify-between">
          <div className="flex flex-1 flex-col gap-4">
            <LanguageSelect sendRequestOnChange={true} />

            <Card>
              <CardHeader>
                <Headline as="h3" className="text-base font-bold">
                  {t('pages.settingsPage.loggedInAs')}
                </Headline>
                <Text as="p" className="text-base">
                  {userEmail}
                </Text>
              </CardHeader>
            </Card>

            <ChangePhone t={t} {...changePhoneProps} />
            <Card>
              <CardHeader>
                <div className="mb-5">
                  <DoYouNeedHelpBox config={config} />
                </div>
                <Button
                  size="lg"
                  className="w-full max-w-60 self-center"
                  onClick={() => {
                    handleLogout({
                      client: authClient,
                      onSuccess: () => {
                        setUserEmail(null);
                        setIsSettingsOpen(false);
                      },
                      onFail: (err) => {
                        //TODO: check if the error is from our backend
                        // display the error
                        console.error('Error:', err);
                      },
                    });
                  }}
                >
                  {t('common.logout')}
                </Button>
              </CardHeader>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default withConfig(SettingsPage);
